import React from "react";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import "./Button.css";
const Button = (props) => {

  return(
    <React.Fragment>
      <button
      onClick={props.onClick} 
      disabled={props.disabled}
      className={`button ${props.icon ? "with-icon" : ""} ${props.disabled ? "disabled" : ""} ${props.color === "transparent" ? "transparent" : "" } ${props.color === "orange" ? "orange" : ""}  ${props.color === "orange-transparent" ? "orange-transparent" : "" } ${props.fullWidth ? "fullwidth" : ""}`}>
      {props.icon} <span className="button-text" >{props.children}</span></button>
    </React.Fragment>
  )

}

export default Button;