import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Categories.css";
import { selectUserProfile } from "../shared/reducers/userProfileSlice";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner/LoadingSpinner";
import { Link } from "react-router-dom";
const Categories = (props) => {
  const userProfile = useSelector(selectUserProfile);
  let lang = userProfile.language;
  let grabMainCategories;
  let labels = {
    seeMore: {
      en: "See more >>",
      ar: "<< شاهد المزيد"
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  
  grabMainCategories = userProfile.categories.map((category) => {
    return (
      <div className={`category-item ${lang === "ar" ? "rtl" : ""}`}>
        <Link to={`/courses/${category.id}`}>
          <div className={`title-box ${lang === "ar" ? "rtl" : ""}`}>
            <div className="parent-category-name">{category.name[lang]}</div>
          </div>
        </Link>
        <div className="subcategories">
          <ul>

            {category.subCategories.map((subCategory) => (
              <li>
                <Link to={`/courses/${category.id}/${subCategory.id}`}>
                  {subCategory.name[lang]}
                </Link>
              </li>
            ))}

          </ul>
          <div className="see-more-category">
            <Link to={`/courses/${category.id}`}>
              {labels.seeMore[lang]}
            </Link>
          </div>
        </div>
      </div>
    )
  });

  return (
    <div className="categories">
      {userProfile.categories.length > 0 ? (
        <div className="parent-categories">
          {grabMainCategories}
        </div>
      )
        :
        (
          <div className="courses-loading-spinner"><LoadingSpinner /></div>
        )
      }
    </div>
  )
}

export default Categories;