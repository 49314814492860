import React from "react";
import PageHeader from "../shared/components/UIElements/PageHeader/PageHeader";
import CourseList from "./CourseList";
import Categories from "./Categories";
// import "./Courses.css";
const Courses = (props) => {
  let lang = props.lang;
  let title = {
    en: "Courses",
    ar: "البرامج التدريبية"
  }
  return (
    <div className='courses'>
    <PageHeader lang={lang} title={title}/>
    {
      // <CourseList lang={lang} />

    }
    <Categories />
    </div>)
}

export default Courses;
