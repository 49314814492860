import React from "react";
import "./UnitStepper.css";
import MovieIcon from '@material-ui/icons/Movie';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import WebIcon from '@material-ui/icons/Web';
import TocIcon from '@material-ui/icons/Toc';
import AssignmentIcon from '@material-ui/icons/Assignment';
import RateReviewIcon from '@material-ui/icons/RateReview';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Skeleton from '@material-ui/lab/Skeleton';
const UnitStepper = (props) => {
  // const courseUnits = [
  //   {
  //     type: "Video",
  //     name: "Welcome"
  //   },
  //   {
  //     type: "Test",
  //     name: "Section 1"
  //   },
  //   {
  //     type: "Content",
  //     name: "Section 2"
  //   },
  //   { type: "Web content",
  //     name: "Section 3"
  //   },
  //   { type: "Audio",
  //     name: "Section 4"
  //   }
  // ];
  let lang = props.lang;
  const icons = {
    "Video": <MovieIcon />,
    "Audio": <VolumeUpIcon />,
    "Web content": <WebIcon />,
    "Content": <TocIcon />,
    "Test": <AssignmentIcon />,
    "Survey": <RateReviewIcon />
  }

  let renderedUnits;
  if (props.units) {
    let courseUnits = props.units.filter((unit) => unit.type !== "Section");
    renderedUnits = courseUnits.map((unit, i) => {
      return (
        <React.Fragment key={unit.id}>
          <div className={`unit-stepper-item ${lang === "ar" ? "rtl" : ""}`}>
            <div className="unit-stepper-icon">
              {icons[unit.type]}
            </div>
            <div className="unit-stepper-text">
              {unit.name}
            </div>
          </div>
        </React.Fragment>
      )
    })
  }
  else {
    renderedUnits = (
      <div className="unit-stepper-loading-spinner">
        <LoadingSpinner />
      </div>
    )
  }
  return (
    <div className={`unit-stepper ${lang === "ar" ? "rtl" : ""}`}>
      {renderedUnits}
    </div>)
}

export default UnitStepper;