import React from "react";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CardSlider.css";
import CourseCard from "./CourseCard";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import entityFormatter from "../../../helpers/entityFormatter";
const LoadingSlider = (props) => {
  let lang = props.lang;
  const entities = {
    "&#36;": "$"
  }
  let settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    rtl: (lang === "ar"),
    initialSlide: (lang === "ar") ? 2 : 0
  }


  return (
    <div className={`card-slider ${lang === "ar" ? "rtl" : ""}`}>

      <div className="home-slider-loading-spinner">
        <LoadingSpinner />
      </div>

    </div>
  );
}

export default LoadingSlider;