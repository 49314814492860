import React, { useState } from "react";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";

import "./LanguageSwitch.css";
import { useSelector, useDispatch } from "react-redux";
import { selectUserProfile, toggleLanguage } from "../../reducers/userProfileSlice";

const LanguageSwitch = () => {
  const userProfile = useSelector(selectUserProfile);
  const lang = userProfile.language;
  // change back to this later
  // const [checked, setChecked] = useState(userProfile.language === "ar" ? true : false);
  // const [checked, setChecked] = useState(true);
  const dispatch = useDispatch();

  const handleChange = () => {
    // setChecked((prev) => !prev);
    dispatch(toggleLanguage({ lang }));
  }
  return (
    <Grid component="label" container alignItems="center" spacing={1}>
      <Grid item className={`switch-label ${lang === "ar" ? null : "__selected"}`}>English</Grid>
      <Grid item>
        <Switch
          checked={lang === "ar"}
          onChange={handleChange}
          value="checked"
        />
      </Grid>
      <Grid item className={`switch-label ${lang === "ar" ? "__selected" : null}`}>عربي</Grid>
    </Grid>
  )
}

export default LanguageSwitch;