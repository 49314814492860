import React from "react";
import "./CartTable.css";
import CartTableItem from "./CartTableItem";
import entityFormatter from "../shared/helpers/entityFormatter";
const CartTable = (props) => {
  let lang = props.lang;
  return (
    <div className="cart-table">
    {props.cart.map((course) => (
      <CartTableItem
        key={course.id}
        lang={lang}
        id={course.id}
        title={course.name}
        avatar={course.avatar}
        instructor={course.custom_field_2}
        price={entityFormatter(course.price)}
        cartChangeHandler={props.cartChangeHandler}
        coupon={props.coupon}
        discountCalculator={props.discountCalculator}
      />
    ))}
    </div>
    )
}

export default CartTable;