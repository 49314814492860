import React, { useState, useEffect } from "react";
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { NavLink, Link, useHistory } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import logo from "../../../images/logo.jpg";
import ContactMailIcon from '@material-ui/icons/ContactMail';
import SchoolIcon from '@material-ui/icons/School';
import ComputerIcon from '@material-ui/icons/Computer';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import "./HeaderDrawer.css";
import Cookies from 'universal-cookie';
import Collapse from '@material-ui/core/Collapse';
import StarBorder from '@material-ui/icons/StarBorder';
import HomeIcon from '@material-ui/icons/Home';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { useSelector, useDispatch } from "react-redux";
import { selectUserProfile, setLoggedIn, setCategories, setCachedCourses, setCoupons } from "../../reducers/userProfileSlice";
import RTL from "../UIElements/RTL/RTL";
const HeaderDrawer = (props) => {
  let labels = props.labels;
  const cookies = new Cookies();
  const userProfile = useSelector(selectUserProfile);
  const useStyles = makeStyles((theme) => ({
    list: {
      width: 300,
    },
    fullList: {
      width: 'auto',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    doubleNested: {
      paddingLeft: theme.spacing(8)
    },
    arabicNested: {
      paddingRight: theme.spacing(4)
    },
    arabicDoubleNested: {
      paddingRight: theme.spacing(8)
    }
  }));

  const drawerHeaderStyles = makeStyles({
    root: {
      backgroundColor: "black",
      borderTop: ".4rem solid rgb(255, 75, 18)",
    }
  })

  // const baseTheme = createMuiTheme({
  //   overrides: {
  //     MuiList: {
  //       padding: {
  //         paddingTop: "0px"
  //       }
  //     }
  //   }
  // });

  const baseTheme = {
    overrides: {
      MuiList: {
        padding: {
          paddingTop: "0px"
        }
      }
    }
  };

  let themeLTR = createMuiTheme(baseTheme);
  let themeRTL = createMuiTheme({ direction: "rtl", ...baseTheme })
  const drawerStyles = makeStyles({
    root: {
      zIndex: 2001
    },
    paper: {
      backgroundColor: "rgb(22, 22, 22)",
      color: "white"
    }
  });

  const dividerStyles = makeStyles({
    root: {
      backgroundColor: "black",
      height: "5px"
    }
  });
  let lang = props.lang;

  const classes = useStyles();
  const drawerClasses = drawerStyles();
  const drawerHeaderClasses = drawerHeaderStyles();
  const dividerClasses = dividerStyles();


  const [state, setState] = useState({
    left: false,
    right: false,
  });

  const [openCoursesTab, setOpenCoursesTab] = useState(false);
  const [categoryTabs, setCategoryTabs] = useState(null);

  const anchors = {
    en: "left",
    ar: "right"
  }

  const toggleDrawer = (anchor, open) => (event) => {
    // console.log("drawer fired");
    setState({ ...state, [anchor]: open });

  };

  const handleLoginClick = (anchor) => {
    setState({ ...state, [anchor]: false })
    props.loginHandler();
  };

  const handleSignUpClick = (anchor) => {
    setState({ ...state, [anchor]: false })
    props.signUpHandler();
  }

  const handleLogoutClick = (anchor) => {
    setState({ ...state, [anchor]: false });
    props.logoutHandler();
  };

  const handleContactUsClick = (anchor) =>{
    setState({ ...state, [anchor]: false });
    props.contactButtonHandler();
  }

  useEffect(() => {
    let compiledCategories = {};
    if (userProfile.categories.length > 0 && !categoryTabs) {
      for (let category in userProfile.categories) {
        compiledCategories[userProfile.categories[category].id] = false;
      }
      setCategoryTabs(compiledCategories);
    }
  }, [userProfile.categories, categoryTabs]);

  const openCategoryTab = (id) => {
    setCategoryTabs({ ...categoryTabs, [id]: !categoryTabs[id] });
  }
  let mappedCategories;

  if (categoryTabs) {
    mappedCategories = userProfile.categories.map((category) => {
      return (
        <React.Fragment key={category.id}>
          <ListItem button className={lang === "ar" ? classes.arabicNested : classes.nested} onClick={() => openCategoryTab(category.id)} >
            <ListItemIcon>

            </ListItemIcon>
            <ListItemText primary={category.name[lang]} />
            {categoryTabs[category.id] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={categoryTabs[category.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding >
              <ListItem button
                className={lang === "ar" ? classes.arabicDoubleNested : classes.doubleNested}
                component={NavLink} to={`/courses/${category.id}`} onClick={toggleDrawer(anchors[lang], !state[anchors[lang]])}>
                <ListItemIcon>

                </ListItemIcon>
                <ListItemText primary={`${labels.all[lang]} ${category.name[lang]}`} />
              </ListItem>

              {category.subCategories.map((subCategory) => (
                <ListItem key={subCategory.id} button
                  className={lang === "ar" ? classes.arabicDoubleNested : classes.doubleNested}
                  component={NavLink} to={`/courses/${category.id}/${subCategory.id}`}
                  onClick={toggleDrawer(anchors[lang], !state[anchors[lang]])}>
                  <ListItemIcon>

                  </ListItemIcon>
                  <ListItemText primary={subCategory.name[lang]} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      )
    })
  }


  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >
      <List >
        <ListItem button classes={drawerHeaderClasses} onClick={toggleDrawer(anchor, false)}>
          <ListItemIcon><MenuIcon style={{ fill: "rgb(255, 75, 18)" }} /></ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography type="body2" style={{ color: "rgb(255, 75, 18)" }}>{labels.daribny[lang]}</Typography>}
          />
        </ListItem>
        <Divider classes={dividerClasses} />
        <ListItem button component={NavLink} to="/" exact onClick={toggleDrawer(anchor, false)}>
          <ListItemIcon><HomeIcon style={{ fill: "rgb(255, 75, 18)" }} /></ListItemIcon>
          <ListItemText primary={labels.home[lang]} />
        </ListItem>

        <ListItem button onClick={() => setOpenCoursesTab(!openCoursesTab)}>
          <ListItemIcon><SchoolIcon style={{ fill: "rgb(255, 75, 18)" }} /></ListItemIcon>
          <ListItemText primary={labels.courses[lang]} />
          {openCoursesTab ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={openCoursesTab} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button
              className={lang === "ar" ? classes.arabicNested : classes.nested}
              component={NavLink} to="/courses"
              onClick={toggleDrawer(anchor, false)}>
              <ListItemIcon>
                <LibraryBooksIcon style={{ fill: "rgb(255, 75, 18)" }} />
              </ListItemIcon>
              <ListItemText primary={labels.allCategories[lang]} />
            </ListItem>
            {userProfile.categories.length > 0 && categoryTabs && mappedCategories}
          </List>
        </Collapse>
        {
          // <ListItem button>
          //   <ListItemIcon><ComputerIcon style={{ fill: "rgb(255, 75, 18)" }} /></ListItemIcon>
          //   <ListItemText primary={labels.livePrograms[lang]} />
          // </ListItem>
        }


        <ListItem button onClick={() => handleContactUsClick(anchor)}>
          <ListItemIcon><ContactMailIcon style={{ fill: "rgb(255, 75, 18)" }} /></ListItemIcon>
          <ListItemText primary={labels.contactUs[lang]} />
        </ListItem>
        {userProfile.loggedIn ? (
          <React.Fragment>
         
          <ListItem button component="a" href={`https://daribny.talentlms.com/index/autologin/key:${cookies.get("redirect")}`}>
            <ListItemIcon><AccountBoxIcon style={{ fill: "rgb(255, 75, 18)" }} /></ListItemIcon>
            <ListItemText primary={labels.myAccount[lang]} />
          </ListItem>

                
          <ListItem button onClick={() => handleLogoutClick(anchor)}>
            <ListItemIcon><ExitToAppIcon style={{ fill: "rgb(255, 75, 18)" }} /></ListItemIcon>
            <ListItemText primary={labels.logout[lang]} />
          </ListItem>
          </React.Fragment>
        ) :
          (
            <React.Fragment>
              <ListItem button onClick={() => handleLoginClick(anchor)}>
                <ListItemIcon><VpnKeyIcon style={{ fill: "rgb(255, 75, 18)" }} /></ListItemIcon>
                <ListItemText primary={labels.login[lang]} />
              </ListItem>

              <ListItem button onClick={() => handleSignUpClick(anchor)}>
                <ListItemIcon><AssignmentIndIcon style={{ fill: "rgb(255, 75, 18)" }} /></ListItemIcon>
                <ListItemText primary={labels.signUp[lang]} />
              </ListItem>
            </React.Fragment>
          )
        }

      </List>
    </div>
  );

  let HeaderDrawerLTR = (
    <React.Fragment>
      <div className={`burger-button-mobile ${lang === "ar" ? "rtl" : ""}`} onClick={toggleDrawer(anchors[lang], !state[anchors[lang]])}>
        <MenuIcon />
      </div>
      <Drawer anchor={anchors[lang]} open={state[anchors[lang]]} onClose={toggleDrawer(anchors[lang], false)} classes={drawerClasses} style={{ zIndex: 2001 }}>
        <ThemeProvider theme={themeLTR}>
          {list(anchors[lang])}
        </ThemeProvider>
      </Drawer>
    </React.Fragment>
  );

  let HeaderDrawerRTL = (
    <React.Fragment>

      <div className={`burger-button-mobile ${lang === "ar" ? "rtl" : ""}`} onClick={toggleDrawer(anchors[lang], !state[anchors[lang]])}>
        <MenuIcon />
      </div>
      <Drawer anchor={anchors[lang]} open={state[anchors[lang]]} onClose={toggleDrawer(anchors[lang], false)} classes={drawerClasses} style={{ zIndex: 2001 }}>
        <RTL>
          <ThemeProvider theme={themeRTL}>
            <span dir="rtl">
              {list(anchors[lang])}
            </span>
          </ThemeProvider>
        </RTL>
      </Drawer>

    </React.Fragment>
  )
  return lang === "ar" ? HeaderDrawerRTL : HeaderDrawerLTR;
};

export default HeaderDrawer;