import React from "react";
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
const OrangeCheckbox = withStyles({
  root: {
    color: "rgb(255, 75, 18)",
    '&$checked': {
      color: "rgb(255, 75, 18)",
    },
    '&$disabled': {
      color: "grey"
    }
  },
  checked: {},
  disabled: {},
})((props) => <Checkbox color="default" {...props} />);

export default OrangeCheckbox;