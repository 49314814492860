import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import { selectUserProfile, setLoggedIn, clearUserCourses } from "../reducers/userProfileSlice";
import axios from "axios";



const userLogout = async (dispatch) => {
  const cookies = new Cookies();
  try {
    let options = {
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/userlogout`,
      withCredentials: true
    }

    let response = await axios(options);
    // console.log(response.data);
    // cookies.remove("token", { path: '/' });
    // cookies.remove("session", { path: '/' });
    // cookies.remove("redirect", { path: '/' });
    dispatch(setLoggedIn(false));
    dispatch(clearUserCourses());
  }
  catch (error) {
    if(error.response.data.error === "The user has already been logged out"){
      // cookies.remove("token", { path: '/' });
      // cookies.remove("session", { path: '/' });
      // cookies.remove("redirect", { path: '/' });
      dispatch(setLoggedIn(false));
      dispatch(clearUserCourses());
    }
  }
  finally{
    // cookies.remove("token", { path: '/' });
    // cookies.remove("session", { path: '/' });
    // cookies.remove("redirect", { path: '/' });
  }
}

export default userLogout;