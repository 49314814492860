import React from "react";
import "./CartTableItem.css";
import { useSelector, useDispatch } from "react-redux";
import { selectUserProfile, removeCourseFromCart } from "../shared/reducers/userProfileSlice";
const CartTableItem = (props) => {
  const userProfile = useSelector(selectUserProfile);
  const dispatch = useDispatch();
  let cart = userProfile.cart;
  let lang = props.lang;
  let labels = {
    removeButton: {
      en: "Remove",
      ar: "ازالة"
    }
  }
  const removeCartCourseHandler = () => {
    dispatch(removeCourseFromCart({ id: props.id }));
    // console.log(props.cartLength)
    props.cartChangeHandler(cart.length);
  }
  let hasLangOptions = typeof props.title === 'object' && props.title !== null;
  return (
    <div className="cart-table-item">
      <div className="cart-table-item-image-container">
        <img src={props.avatar} />
      </div>
      <div className={`cart-table-item-text-content ${lang === "ar" ? "rtl" : ""}`}>
        <div className="cart-table-item-title">
          {hasLangOptions ? props.title[lang] : props.title}

        </div>
        <div className="cart-table-item-instructor">
          {props.instructor}

        </div>
      </div>
      <div className={`cart-table-item-price-and-interactables ${lang === "ar" ? "rtl" : ""}`}>
        {props.coupon ?
          (
            <div className="cart-table-item-price-with-discount">
              <div className="cart-table-item-original-price">
                {props.price}
              </div>
              <div className="cart-table-item-discounted-price">
                ${props.discountCalculator(props.price, props.coupon.Discount)}
              </div>

            </div>


          ) :
          (
            <div className="cart-table-item-price">
              {props.price}
            </div>
          )}

        <div className="cart-table-item-interactables">
          <div onClick={removeCartCourseHandler}>{labels.removeButton[lang]}</div>
        </div>
      </div>

    </div >
  )
}

export default CartTableItem;