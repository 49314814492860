import React from "react";
import "./CourseItem.css";
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Box from "@material-ui/core/Box";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUserProfile, addToCart, removeCourseFromCart } from "../shared/reducers/userProfileSlice";
import Button from "../shared/components/UIElements/Button/Button";
const CourseItem = (props) => {
  let hasLangOptions = typeof props.title === 'object' && props.title !== null;
  let lang = props.lang;

  let labels = {
    addCourseToCart: {
      en: "Add to cart",
      ar: "أضف إلى السلة"
    },
    removeFromCart: {
      en: "Remove from cart",
      ar: "إزالة من السلة"
    },
    courseOwnedLabel: {
      en: "You already own this course",
      ar: "أنت تمتلك هذه الدورة بالفعل"
    }
  }
  const userProfile = useSelector(selectUserProfile);
  const dispatch = useDispatch();
  const history = useHistory();
  const isInCart = userProfile.cart.some((course) => course.id === props.id);
  const clickHandler = (e) => {
    // e.stopPropagation();
    // e.nativeEvent.stopImmediatePropagation();
    // to={`/course/${props.id}`}
    history.push(`/course/${props.id}`)
    // console.log("course container clicked");
  }

  const cartButtonHandler = (e) => {
    e.stopPropagation();
    if (userProfile.cart.some((course) => course.id === props.id)) {
      dispatch(removeCourseFromCart({ id: props.id }));
    }
    else {
      // console.log("courseDetails", props.courseDetails)
      dispatch(addToCart(props.courseDetails));
    }
  }
  return (
    <div className="course-item-link" onClick={clickHandler}>
      <div className={`course-item ${lang === "ar" ? "rtl" : ""}`}>
        <div className="course-item-image-container">
          <img src={props.image} />
        </div>
        <div className={`course-item-text-content ${lang === "ar" ? "rtl" : ""}`}>
          <div className="course-item-title">
            {hasLangOptions ? props.title[lang] : props.title}
            {userProfile.userCourses.some((course) => course.id === props.id) && (
              <span className="course-owned">{labels.courseOwnedLabel[lang]}</span>
            )}
          </div>
          <div className="course-item-instructor">
          {props.instructor}
          </div>
          
          {userProfile.userCourses.some((course) => course.id === props.id) && (
            <div className="course-owned-mobile"><span>{labels.courseOwnedLabel[lang]}</span></div>
          )}
          <div className="course-item-description">
            {props.description}
          </div>

          <div className={`course-item-price-and-rating-mobile ${lang === "ar" ? "rtl" : ""}`}>
            <div className={`course-item-rating ${lang === "ar" ? "rtl" : ""}`}>
              <Rating
                name="read-only"
                size="small"
                value={props.rating}
                precision={0.5}
                readOnly
                emptyIcon={<StarBorderIcon style={{ color: "white" }} fontSize="inherit" />}
              />
              <Box className={`rating-label ${lang === "ar" ? "rtl" : ""}`}>{props.rating}</Box>
            </div>

            <div className="course-item-price">
              {props.price}

              <div className={`course-item-button ${lang === "ar" ? "rtl" : ""}`}>
              {!userProfile.userCourses.some((course) => course.id === props.id) && (props.price !== "$0.00") && (
                <Button color={"orange"} onClick={cartButtonHandler}>{isInCart ? labels.removeFromCart[lang] : labels.addCourseToCart[lang]}</Button>
              )}
            </div>
            </div>

            {props.userCount && (
              <div className="students-enrolled">

                ({props.userCount} {lang === "ar" ? " :طلاب" : "students"})
              </div>
            )}
      

            
          </div>

        </div>
        <div className={`course-item-price-and-rating ${lang === "ar" ? "rtl" : ""}`}>
          <div className="course-item-price">
            {props.price}
          </div>

          <div className={`course-item-rating ${lang === "ar" ? "rtl" : ""}`}>
            <Rating
              name="read-only"
              size="small"
              value={props.rating}
              precision={0.5}
              readOnly
              emptyIcon={<StarBorderIcon style={{ color: "white" }} fontSize="inherit" />}
            />
            <Box className={`rating-label ${lang === "ar" ? "rtl" : ""}`}>{props.rating}</Box>
          </div>
          {props.userCount && (
            <div className="students-enrolled">

              ({props.userCount} {lang === "ar" ? " :طلاب" : "students"})
            </div>
          )}
          <div className={`course-item-button ${lang === "ar" ? "rtl" : ""}`}>
            {!userProfile.userCourses.some((course) => course.id === props.id) && (props.price !== "$0.00") && (
              <Button color={"orange"} onClick={cartButtonHandler}>{isInCart ? labels.removeFromCart[lang] : labels.addCourseToCart[lang]}</Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseItem;