import React, { useState, useEffect } from "react";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import OrangeCheckbox from "./OrangeCheckbox";
import RTL from "../RTL/RTL";
const LanguageCheckboxGroup = (props) => {
  const [state, setState] = useState(props.languageState);
  let lang = props.lang;
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    // if(event.target.checked){
    props.setLanguageFilter({ [event.target.name]: event.target.checked });
    // }
  };

  let labels = {
    englishFilter: {
      en: "English",
      ar: "الإنجليزية"
    },
    arabicFilter: {
      en: "Arabic",
      ar: "عربى"
    },
    sectionHeader: {
      en: "Languages",
      ar: "لغات"
    }
  };

  useEffect(() => {
    // console.log("Checkbox mounted");
    setState(props.languageState);
  }, [])

  const useStyles = makeStyles({
    root: {
      backgroundColor: "black",
      color: "white",
      marginRight: "10%"
    },
  });

  const classes = useStyles();

  const arabicStyles = makeStyles({
    root: {
      backgroundColor: "black",
      color: "white",
    }
  });

  const arabicClasses = arabicStyles();
  const theme = createMuiTheme({
    direction: "rtl"
  });
  const LanguageCheckboxesLTR = (
    <FormGroup classes={classes}>
      <div className="filter-section-header">{labels.sectionHeader[lang]}</div>
      <FormControlLabel
        control={<OrangeCheckbox checked={state.English} onChange={handleChange} name={"English"} />}
        label={labels.englishFilter[lang]}
      />
      <FormControlLabel
        control={<OrangeCheckbox checked={state.Arabic} onChange={handleChange} name={"Arabic"} />}
        label={labels.arabicFilter[lang]}
      />
    </FormGroup>
  );

  const LanguageCheckboxesRTL = (
    <RTL>
      <ThemeProvider theme={theme}>
        <span dir="rtl">
          <FormGroup classes={arabicClasses}>
            <div className="filter-section-header">{labels.sectionHeader[lang]}</div>
            <FormControlLabel
              control={<OrangeCheckbox checked={state.English} onChange={handleChange} name={"English"} />}
              label={labels.englishFilter[lang]}
            />
            <FormControlLabel
              control={<OrangeCheckbox checked={state.Arabic} onChange={handleChange} name={"Arabic"} />}
              label={labels.arabicFilter[lang]}
            />
          </FormGroup>
        </span>
      </ThemeProvider>
    </RTL>
  )

  return (
   lang === "ar" ? LanguageCheckboxesRTL : LanguageCheckboxesLTR
  )
}

export default LanguageCheckboxGroup;