import React, { useEffect, useState } from "react";
import logo from "../../../images/logo.jpg";
import "./Header.css";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import ShoppingCartSharpIcon from '@material-ui/icons/ShoppingCartSharp';
import Banner from "../UIElements/Banner/Banner";
import Badge from '@material-ui/core/Badge';
import Modal from "../UIElements/Modal/Modal";
import LanguageSwitch from "./LanguageSwitch";
import Button from "../UIElements/Button/Button";
import SearchInput from "../UIElements/SearchInput/SearchInput";
import Authentication from "../FormElements/Authentication/Authentication";
import Cookies from 'universal-cookie';
import axios from "axios";
import userLogout from "../../helpers/userLogout";
import { useSelector, useDispatch } from "react-redux";
import { selectUserProfile, setLoggedIn, setCategories, setCachedCourses, setCoupons } from "../../reducers/userProfileSlice";
import SuccessModal from "../UIElements/SuccessModal/SuccessModal";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MenuIcon from '@material-ui/icons/Menu';
import Skeleton from '@material-ui/lab/Skeleton';
import LanguageSwitchMobile from "./LanguageSwitchMobile";
import SearchIcon from "@material-ui/icons/Search";
import HeaderDrawer from "./HeaderDrawer";
import EmailForm from "../FormElements/EmailForm/EmailForm";
const Header = (props) => {
  const cookies = new Cookies();
  // console.log(cookies.get("login_key"));
  let location = useLocation();
  const userProfile = useSelector(selectUserProfile);
  const dispatch = useDispatch();
  let lang = props.lang;
  const [showSignUp, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const [success, setSuccess] = useState("");
  const [showBanner, setShowBanner] = useState(false);
  const [bannerData, setBannerData] = useState();
  const [searchButtonClicked, setSearchButtonClicked] = useState(false);
  const history = useHistory();

  // const [loggedIn, setLoggedIn] = useState(false);
  const labels = {
    home: {
      en: "HOME",
      ar: "الصفحة الرئيسية"
    },
    courses: {
      en: "COURSES",
      ar: "البرامج التدريبية"
    },
    trainingCompanies: {
      en: "TRAINING COMPANIES",
      ar: "المؤسسات التعليمية"
    },
    livePrograms: {
      en: "LIVE PROGRAMS",
      ar: "البرامج المباشرة"
    },
    business: {
      en: "FOR BUSINESS",
      ar: "أشتراك المؤسسات"
    },
    instructor: {
      en: "BECOME AN INSTRUCTOR",
      ar: "درب معنا"
    },
    myAccount: {
      en: "MY ACCOUNT",
      ar: "حسابي"
    },
    signUp: {
      en: "SIGN UP",
      ar: "اشترك معنى"
    },
    login: {
      en: "LOGIN",
      ar: "دخول"
    },
    contactUs: {
      en: "CONTACT US",
      ar: "تواصل معنا"
    },
    logout: {
      en: "LOGOUT",
      ar: "خروج"
    },
    daribny: {
      en: "DARIBNY",
      ar: "دربني"
    },
    all: {
      en: "ALL",
      ar: "جميع برامج"
    },
    allCategories: {
      en: "ALL CATEGORIES",
      ar: "جميع الفئات"
    }
  }

  const resizeHeaderOnScroll = () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 200,
      headerEl = document.getElementsByClassName("header-logo")[0];

    if (distanceY > shrinkOn) {
      headerEl.classList.add("smaller");
    } else {
      headerEl.classList.remove("smaller");
    }
  }

  const contactButtonHandler = () => {
    setShowContactUs(true);
  };

  const closeContactUsModalHandler = (message) => {
    setShowContactUs(false);
    setSuccess(message)
  }
  const logoutHandler = async () => {
    // try {
    //   let options = {
    //     method: "post",
    //     url: "/api/v1/userlogout",
    //     data: {
    //       token: cookies.get("token")
    //     }
    //   }

    //   let response = await axios(options);
    //   console.log(response.data);
    //   cookies.remove("token");
    //   cookies.remove("session");
    //   cookies.remove("redirect");
    //   dispatch(setLoggedIn(false));
    // }
    // catch (error) {
    //   if(error.response.data.error === "The user has already been logged out"){
    //     cookies.remove("token");
    //     cookies.remove("session");
    //     cookies.remove("redirect");
    //     dispatch(setLoggedIn(false));
    //   }
    // }
    userLogout(dispatch);
  }

  const bannerHandler = () => {
    setShowBanner(false);
  }

  useEffect(() => {
    window.addEventListener("scroll", resizeHeaderOnScroll);
  }, [])

  useEffect(() => {
    if (cookies.get("redirect")) {
      dispatch(setLoggedIn(true));
    }
  }, []);

  useEffect(() => {
    const getCategories = async () => {
      let options = {
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/categories`
      }

      let response = await axios(options);
      // console.log(response.data);
      dispatch(setCategories(response.data.categories));
      dispatch(setCachedCourses({ courses: response.data.flattenedCourseList }));
    }
    if (userProfile.categories.length === 0) {
      getCategories();
      // console.log(userProfile.categories);
    }
    // console.log("categories updated");
  }, []);

  useEffect(() => {
    const getCoupons = async () => {
      let options = {
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/coupons`,
      }

      let response = await axios(options);
      // console.log("coupon response", response.data);
      let validCoupons = response.data.coupons.filter((coupon) => new Date(coupon.To) > new Date());
      // console.log("valid coupons", validCoupons);
      if (validCoupons.length > 0) {
        setBannerData({ ...response.data, coupons: validCoupons });
        setShowBanner(true);

        dispatch(setCoupons(validCoupons));
      }

    };

    getCoupons();
  }, [])


  // let mappedCategories = userProfile.categories.map((category) => {
  //   <li>
  //     <NavLink to="/courses/business" activeClassName="selected">
  //       {ca}
  //     </NavLink>
  //   </li>
  // })

  let mappedCategories = userProfile.categories.map((category) => {
    return (
      <li key={category.id}>
        <NavLink to={`/courses/${category.id}`} >

          {category.name[lang]}
        </NavLink>
        <span className={`child-indicator ${lang === "ar" ? "rtl" : ""}`}><NavigateNextIcon /></span>
        <ul>
          {category.subCategories.map((subCategory) => (
            <li key={subCategory.id}>
              <NavLink to={`/courses/${category.id}/${subCategory.id}`} >
                {subCategory.name[lang]}
              </NavLink>
            </li>
          ))}
        </ul>

      </li>
    )
  })

  // const categorySkeleton = (
  //   <ul>
  //     <li>
  //       <a>
  //       <Skeleton width={100} variant='rect' height={50} style={{ backgroundColor: "rgb(255, 75, 18)", padding: "2%",  }} />

  //       </a>

  //     </li>
  //     <li>
  //     <a>
  //     <Skeleton width={100} variant='rect' height={50} style={{ backgroundColor: "rgb(255, 75, 18)", padding: "2%", }} />

  //     </a>


  //     </li>
  //   </ul>
  // )
  const loginHandler = () => {
    setShowLogin(true);
  };

  const signUpHandler = () => {
    setShowSignup(true);
  }
  const closeSignupModalHandler = (message) => {
    setShowSignup(false);

    setSuccess(message);
  }

  const closeLoginModalHandler = (message) => {
    setShowLogin(false);
    setSuccess(message);
  }
  const logoHandler = () => {
    // console.log("logo clicked");
    history.push("/");
  };

  const searchSubmitHandler = () => {
    setSearchButtonClicked(false);
  }


  return (
    <nav className="header">
      {showBanner && (
        <Banner lang={lang} bannerHandler={bannerHandler} bannerData={bannerData} />
      )}
      <div className="divider" />
      <Modal
        show={showSignUp}
        onCancel={() => setShowSignup(false)}
        headerClass={props.lang === "ar" ? "sign-up__modal-header-rtl" : ""}
        header={labels.signUp[lang]}
        contentClass="sign-up__modal-content"
      >
        <div>
          <Authentication lang={lang} signUpMode={true} closeModal={closeSignupModalHandler} />
        </div>
      </Modal>

      <Modal
        show={showLogin}
        headerClass={props.lang === "ar" ? "sign-up__modal-header-rtl" : ""}
        onCancel={() => setShowLogin(false)}
        header={labels.login[lang]}
        contentClass="sign-up__modal-content"
      >
        <div>
          <Authentication lang={lang} closeModal={closeLoginModalHandler} />
        </div>
      </Modal>

      <Modal
        show={showContactUs}
        headerClass={props.lang === "ar" ? "sign-up__modal-header-rtl" : ""}
        onCancel={() => setShowContactUs(false)}
        header={labels.contactUs[lang]}
        contentClass="sign-up__modal-content"
      >
        <div>
          <EmailForm lang={lang} closeModal={closeContactUsModalHandler} />
        </div>
      </Modal>

      <SuccessModal success={success} onClear={() => setSuccess(null)} />
      <div className={`navbar-elements ${lang === "ar" ? "row-rtl" : ""}`}>


        <div className={`header-logo-container ${lang === "ar" ? "rtl" : ""}`} >
          <HeaderDrawer
            lang={lang}
            loginHandler={loginHandler}
            signUpHandler={signUpHandler}
            logoutHandler={logoutHandler}
            contactButtonHandler={contactButtonHandler}
            labels={labels}
          />
          <img className="header-logo" src={logo} onClick={logoHandler} />
        </div>

        <div className={`header-links-container`}>
          <ul className={`${lang === "ar" ? "rtl" : ""}`}>
            <li>
              <NavLink to="/courses" activeClassName="selected">
                {labels.courses[lang]}
              </NavLink>

              {userProfile.categories.length > 0 && (
                <ul className={`${showBanner ? "" : "banner-hidden"}`}>
                  {mappedCategories}
                </ul>
              )}

            </li>
            {
              // <li>
              //   <NavLink to="/live-programs" activeClassName="selected">
              //     {labels.livePrograms[lang]}
              //   </NavLink>
              // </li> 
            }
            <li>
              <a onClick={contactButtonHandler}>{labels.contactUs[lang]}</a>
              {
                // <NavLink to="/contact-us" onClick={contactButtonHandler} activeClassName="selected">
                //   {labels.contactUs[lang]}
                // </NavLink>
              }
              {
                // <ul className={`${showBanner ? "" : "banner-hidden"}`}>
                //   <li>

                //     <NavLink to="/for-business" activeClassName="selected">
                //       {labels.business[lang]}
                //     </NavLink>
                //   </li>
                //   <li>
                //     <NavLink to="/teach-on-daribny" activeClassName="selected">
                //       {labels.instructor[lang]}
                //     </NavLink>
                //   </li>
                // </ul>
              }
            </li>
            <li className="search-input">
              <SearchInput lang={lang} fullWidth={true} />
            </li>
            <li>
              <NavLink to="/cart" activeClassName="selected">
                <Badge badgeContent={userProfile.cart.length} color="error">
                  <ShoppingCartSharpIcon />
                </Badge>
              </NavLink>
            </li>
            <li>
              {userProfile.loggedIn ? (
                <a href={`https://daribny.talentlms.com/index/autologin/key:${cookies.get("redirect")}`}>
                  {labels.myAccount[lang]}
                </a>
              )
                :
                (
                  <a onClick={() => setShowLogin(true)}>{labels.login[lang]}</a>
                )}
            </li>
            <li>
              {userProfile.loggedIn ? (
                <a onClick={logoutHandler}>{labels.logout[lang]}</a>
              )
                :
                (
                  <a onClick={() => setShowSignup(true)}>
                    {labels.signUp[lang]}
                  </a>
                )}
            </li>
          </ul>
        </div>

        <div className={`language-switch ${lang === "ar" ? "rtl" : ""}`}>
          <LanguageSwitch />
        </div>

        <div className={`header-button-row-mobile ${lang === "ar" ? "rtl" : ""}`}>
          <div className="search-button-mobile" onClick={() => setSearchButtonClicked(!searchButtonClicked)}>
            <SearchIcon />
          </div>
          <div className="shopping-cart-button-mobile">
            <NavLink to="/cart" activeClassName="selected">
              <Badge badgeContent={userProfile.cart.length} color="error">
                <ShoppingCartSharpIcon />
              </Badge>
            </NavLink>
          </div>

          <LanguageSwitchMobile />
        </div>
      </div>
      {searchButtonClicked && (
        <div className="search-input-mobile-container">
          <div className="search-input-mobile">
            <SearchInput lang={lang} fullWidth={true} isMobile={true} searchSubmitHandler={searchSubmitHandler} />
          </div>
        </div>
      )}
    </nav>
  )
}

export default Header;