import React, {useState, useEffect} from "react";

const CountdownTimer = (props) => {
  let lang = props.lang
  let labels = {
    bannerTimer: {
      en: "Ends in",
      ar: "تنتهي البرامج في"
    },
    bannerOver: {
      en: "Time's up!",
      ar: "انتهى الوقت!"
    }
  }
  const calculateTimeLeft = () => {
    const difference = +new Date(props.time) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        d: Math.floor(difference / (1000 * 60 * 60 * 24)),
        h: Math.floor((difference / (1000 * 60 * 60)) % 24),
        m: Math.floor((difference / 1000 / 60) % 60),
        s: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval, index) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={index}>
        {timeLeft[interval]}{interval}{" "}
      </span>
    );
  });

  return (
    <React.Fragment>

     {timerComponents.length ? 
      (
        <React.Fragment>
          <div>{labels.bannerTimer[lang]}</div>
          <span>{timerComponents}</span>
        </React.Fragment>
        
        ) : 
      (<span><bdi>{labels.bannerOver[lang]}</bdi></span>)}
    </React.Fragment>
  );
}

export default CountdownTimer;