import React from 'react';
import ReactDOM from "react-dom";
import Backdrop from "../Backdrop/Backdrop";
import { CSSTransition } from "react-transition-group";
import "./Modal.css";

const ModalOverLay = (props) => {
  const content = (
    <div className={`modal ${props.className}`} style={props.style}>
      <header className={`modal__header ${props.headerClass}`}>

        <div className="modal__header-title-container">
          <h2>{props.header}</h2>

        </div>
      </header>
        <div className={`modal__content ${props.contentClass}`}>
          {props.children}
        </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById("modal-hook"));
};

const Modal = (props) => {
  return (
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="modal"
      >
        <ModalOverLay {...props} />
      </CSSTransition>
    </React.Fragment>
  )
}

export default Modal;