import { createSlice } from "@reduxjs/toolkit";


export const slice = createSlice({
  name: "userProfile",
  initialState: {
    loggedIn: false,
    language: "ar",
    cachedCourses: [],
    cart: [],
    userCourses: [],
    branches: [],
    categories: [],
    searchedCourses: [],
    session: "",
    expirationDate: "",
    coupons: []
  },
  reducers: {
    toggleLanguage: (state, action) => {
      let lang;
      lang = action.payload.lang === "en" ? "ar" : "en";
      state.language = lang;
    },
    setCachedCourses: (state, action) => {
      // console.log(action.payload.courses);
      state.cachedCourses = [...action.payload.courses];
    },
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    addToCart: (state, action) => {
      state.cart.push(action.payload);
    },
    removeCourseFromCart: (state, action) => {
      let courseToRemove = state.cart.findIndex((course) => course.id === action.payload.id);
      state.cart.splice(courseToRemove, 1);
    },
    setUserCourses: (state, action) => {
      state.userCourses = [...action.payload.courses];
    },
    clearUserCourses: (state, action) => {
      state.userCourses.length = 0;
    },
    setCart: (state, action) => {
      state.cart = [...action.payload];
    },
    setCategories: (state, action) => {
      state.categories = [...action.payload];
    },
    updateCategoryCourses: (state, action) => {
      let parentID = action.payload.parentID;
      let courses = action.payload.courses;

      let parentIndex = state.categories.findIndex((category) => category.id === parentID);
      let modifiedCategoryCourses = state.categories[parentIndex].courses.map(x => {
        const item = courses.find(({ id }) => id === x.id);
        return item ? item : x;
      });

      modifiedCategoryCourses.sort((a, b) => b.rating - a.rating);
      state.categories[parentIndex].courses = [...modifiedCategoryCourses];
    },
    updateCachedCourses: (state, action) => {
      let courses = action.payload.courses;
      let modifiedCachedCourses = state.cachedCourses.map(x => {
        const item = courses.find(({ id }) => id === x.id);
        return item ? item : x;
      });
      modifiedCachedCourses.sort((a, b) => b.rating - a.rating);
      // console.log("modifiedCachedCourses", modifiedCachedCourses);
      state.cachedCourses = [...modifiedCachedCourses];
    },
    setSearchedCourses: (state, action) => {

    },
    setSession: (state, action) => {
      state.session = action.payload;
    },
    setExpirationDate: (state, action) => {
      state.expirationDate = action.payload;
    },
    setCoupons: (state, action) => {
      state.coupons = [...action.payload];
    },
    setBranches: (state, action) => {
      state.branches = [...action.payload];
    }
  }
});

export const {
  toggleLanguage,
  setCachedCourses,
  setLoggedIn,
  addToCart,
  setUserCourses,
  clearUserCourses,
  removeCourseFromCart,
  setCart,
  setCategories,
  updateCategoryCourses,
  updateCachedCourses,
  setSession,
  setExpirationDate,
  setCoupons,
  setBranches
} = slice.actions;
export const selectUserProfile = state => state.userProfile;

export default slice.reducer;