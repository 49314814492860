import React, { useEffect, useState } from "react";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import PageHeader from "../shared/components/UIElements/PageHeader/PageHeader";
import Button from "../shared/components/UIElements/Button/Button";
import "./Payment.css";
import axios from "axios";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner/LoadingSpinner";
import ErrorModal from "../shared/components/UIElements/ErrorModal/ErrorModal";
import userLogout from "../shared/helpers/userLogout";
const Payment = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("")
  let lang = props.lang;
  let title = {
    en: "Payment",
    ar: "دفع"
  };

  let labels = {
    summaryLabel: {
      en: "Summary",
      ar: "ملخص"
    },
    originalPrice: {
      en: "Original Price",
      ar: "السعر الأصلي"
    },
    subtotal: {
      en: "Subtotal",
      ar: "المجموع الفرعي"
    },
    discount: {
      en: "Discount",
      ar: "خصم"
    },
    totalPrice: {
      en: "Total",
      ar: "مجموع"
    },
    finalizePayment: {
      en: "Finalize payment",
      ar: "إنهاء الدفع"
    }
  }
  const location = useLocation();
  const history = useHistory();
  const cookies = new Cookies();
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.state && cookies.get("redirect")) {
      // console.log("successfull redirect");
      // console.log(location.state)
    }
    else {
      history.push("/");
    }
  }, []);

  const handlePayment = async () => {
    try {
      setLoading(true);
      // console.log(location.state.cart[0].id)
      if (location.state.cart.length === 1) {
        let options = {
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/buycourse`,
          data: {
            course_id: location.state.cart[0].id
          },
          withCredentials: true
        }

        if(location.state.coupon){
          options.data.coupon = location.state.coupon
        }

        let response = await axios(options);
        // console.log(response.data);
        window.location.replace(response.data.redirect_url)
      }
      else {
        let options = {
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/buycourses`,
          data: {
            price: location.state.subtotal,
            cart: location.state.cart
          },
          withCredentials: true
        }

        if(location.state.coupon){
          options.data.coupon = location.state.coupon;
          options.data.discount = location.state.discount;
        }
        let response = await axios(options);
        // console.log(response.data.redirect_url);
        window.location.replace(response.data.redirect_url);
      }
    }
    catch (error) {
      // console.log(error.message);
      setError("Authentication failed. Please login and try again.")
    }
  }

  const errorModalHandler = () => {
    userLogout(dispatch);
  }
  return (
    <div className="payment">
      <ErrorModal error={error} onClear={errorModalHandler} />
      <PageHeader lang={lang} title={title} />
      {location.state && cookies.get("redirect") ? (
        <div className={`payment-summary ${lang === "ar" ? "rtl" : ""}`}>
          <div className="table-heading">
            {labels.summaryLabel[lang]}
        </div>
          <table className="payment-details">
            <tbody>
              <tr>
                <td className="row-label">
                  {labels.originalPrice[lang]}
                </td>
                <td className="row-data">
                  ${parseFloat(location.state.originalPrice).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className="row-label">
                  {labels.discount[lang]}
                </td>
                <td className="row-data">
                  ${parseFloat(location.state.discount).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>

          <table>
            <tbody>
              <tr>
                <td className="row-label">
                  {labels.subtotal[lang]}

              </td>
                <td className="row-data">

                  ${parseFloat(location.state.subtotal).toFixed(2)}

                </td>
              </tr>
            </tbody>
          </table>
          <table className="table-total">
            <tbody>
              <tr>
                <td className="row-label strong-row">
                  {labels.totalPrice[lang]}

                </td>
                <td className="row-data strong-row">

                  ${parseFloat(location.state.subtotal).toFixed(2)}

                </td>
              </tr>
            </tbody>
          </table>
          {loading ?
            (
              <div className="payment-loading-spinner">
                <LoadingSpinner size={50} />
              </div>
            )
            :
            (
              <div className="finalize-payment-button">
                <Button color={"orange-transparent"} onClick={handlePayment}>{labels.finalizePayment[lang]}</Button>
              </div>

            )
          }
        </div>
      ) : (
          <Redirect to={"/"} />
        )}

    </div>)

}

export default Payment;