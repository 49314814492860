const entities = {
  "&#36;": "$",
  "&amp;": "&",
}

const entityFormatter = (stringToFormat) => {
  return stringToFormat.replace(/&#?\w+;/, match => entities[match]).replace(/,/g, '');
}


export default entityFormatter;