import React, { useState } from "react";
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from "../../UIElements/Button/Button";
import RTL from "../../UIElements/RTL/RTL";
import axios from "axios";
import LoadingSpinner from "../../UIElements/LoadingSpinner/LoadingSpinner"
const EmailForm = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const isValidEmail = /\S+@\S+\.\S+/;
  let enableContactButton = firstName && lastName && isValidEmail.test(email) && message;
  let labels = {
    formFirstName: {
      en: "First Name",
      ar: "الاسم الاول"
    },
    formLastName: {
      en: "Last Name",
      ar: "اسم العائلة"
    },
    email: {
      en: "Email",
      ar: "البريد الالكتروني"
    },
    message: {
      en: "Message",
      ar: "رسالة"
    }
  }
  const useStyles = makeStyles({
    root: {
      marginBottom: "5%",
      '& label.Mui-focused': {
        color: "white",
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        }
      }
    },
    input: {
      color: "white",
      '&::placeholder': {
        color: 'white'
      }
    },
    cssLabel: {
      color: 'white',
      '&.Mui-focused': {
        color: "white",
      }
    },

    notchedOutline: {
      borderWidth: '1px',
      borderColor: 'rgb(255, 75, 18) !important'
    }
  });

  const baseTheme = {
    typography: {
      "fontFamily": `"Helvetica", "Arial", sans-serif`,
      "fontSize": 14,
      "fontWeightLight": 300,
      "fontWeightRegular": 400,
      "fontWeightMedium": 500
    },
    overrides: {
      MuiFormControl: {
        root: {
          color: "white",
          border: "0",
          margin: "0",
          marginTop: "0px",
          marginBottom: "0px",
          display: "inline-flex",
          padding: "0",
          position: "relative",
          minWidth: "0",
          flexDirection: "column",
          verticalAlign: "none"
        },
        marginNormal: {
          margin: "0",
          paddingTop: "40px",
        },
      },
      MuiInputBase: {
        root: {
          color: "white"
        }
      },
      MuiAutocomplete: {
        inputRoot: {
          '& $input': {
            width: "auto",
          },
          '$hasPopupIcon &, $hasClearIcon &': {
            paddingRight: 0,
          },
        },
        hasClearIcon: {
          padding: "0%"
        },
        popper: {
          direction: "ltr",
          zIndex: "20004",
          position: "relative",
          top: "50px"

        },
        popperDisablePortal: {

        },
        paper: {
          color: "white",
          backgroundColor: "black",
          boxShadow: "0 0 2px white"
        },
        option: {
          '&[data-focus="true"]': {
            backgroundColor: "rgb(255, 75, 18)",
          },
        },
        noOptions: {
          color: "white"
        },
        loading: {
          color: "white",
          padding: '14px 16px',
        },
      },
    },
  };
  const classes = useStyles();
  const themeLTR = createMuiTheme(baseTheme);

  const themeRTL = createMuiTheme({ direction: "rtl", ...baseTheme });

  let lang = props.lang;

  const submitHandler = async (e) => {
    e.preventDefault();
    // console.log("form submitted");
    // console.log(message);
    try {
      setLoading(true);
      const data = {
        first_name: firstName,
        last_name: lastName,
        email,
        message
      }
      let options = {
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/emailform`,
        data,
      };
      let response = await axios(options);
      // console.log(response.data);
      // if(!error){
      props.closeModal(response.data.message);
      setLoading(false);
      // }
    }
    catch (error) {
      setLoading(false);
      // console.log(error.response.data.error);
      setError(error.response.data.error);
    }

  };

  let LeftToRight = (
    <ThemeProvider theme={themeLTR}>
      <div className="name-inputs">
        <div className="first-name">
          <TextField

            variant="outlined"
            label={labels.formFirstName[lang]}
            fullWidth={true}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder={labels.formFirstName[lang]}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.root,
                input: classes.input,
                underline: classes.underline,
                notchedOutline: classes.notchedOutline,
              }
            }
            }
          />
        </div>
        <div className="last-name">
          <TextField
            fullWidth={true}
            variant="outlined"
            label={labels.formLastName[lang]}
            placeholder={labels.formLastName[lang]}
            onChange={(e) => setLastName(e.target.value)}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.root,
                input: classes.input,
                underline: classes.underline,
                notchedOutline: classes.notchedOutline,
              }
            }
            }
          />
        </div>
      </div>

      <TextField
        fullWidth={true}
        variant="outlined"
        label={labels.email[lang]}
        placeholder={labels.email[lang]}
        onChange={(e) => setEmail(e.target.value)}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.root,
            input: classes.input,
            underline: classes.underline,
            notchedOutline: classes.notchedOutline,
          }
        }
        }
      />



      <TextField
        fullWidth={true}
        variant="outlined"
        label={labels.message[lang]}
        placeholder={labels.message[lang]}
        multiline
        rowsMax={10}
        rows={8}
        onChange={(e) => setMessage(e.target.value)}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.root,
            input: classes.input,
            underline: classes.underline,
            notchedOutline: classes.notchedOutline,
          }
        }
        }
      />
    </ThemeProvider>
  );

  let RightToLeft = (
    <RTL>
      <ThemeProvider theme={themeRTL}>
        <span dir="rtl">
          <div className="name-inputs">
            <div className="first-name">
              <TextField

                variant="outlined"
                label={labels.formFirstName[lang]}
                fullWidth={true}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder={labels.formFirstName[lang]}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.root,
                    input: classes.input,
                    underline: classes.underline,
                    notchedOutline: classes.notchedOutline,
                  }
                }
                }
              />
            </div>
            <div className="last-name">
              <TextField
                fullWidth={true}
                variant="outlined"
                label={labels.formLastName[lang]}
                placeholder={labels.formLastName[lang]}
                onChange={(e) => setLastName(e.target.value)}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.root,
                    input: classes.input,
                    underline: classes.underline,
                    notchedOutline: classes.notchedOutline,
                  }
                }
                }
              />
            </div>
          </div>

          <TextField
            fullWidth={true}
            variant="outlined"
            label={labels.email[lang]}
            placeholder={labels.email[lang]}
            onChange={(e) => setEmail(e.target.value)}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.root,
                input: classes.input,
                underline: classes.underline,
                notchedOutline: classes.notchedOutline,
              }
            }
            }
          />



          <TextField
            fullWidth={true}
            variant="outlined"
            label={labels.message[lang]}
            placeholder={labels.message[lang]}
            multiline
            rowsMax={10}
            rows={8}
            onChange={(e) => setMessage(e.target.value)}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.root,
                input: classes.input,
                underline: classes.underline,
                notchedOutline: classes.notchedOutline,
              }
            }
            }
          />
        </span>
      </ThemeProvider>
    </RTL>
  );

  return (
    <form onSubmit={submitHandler}>
      {lang === "ar" ? RightToLeft : LeftToRight}
      <div className="authentication-footer">
        {loading ? (
          <div className="payment-loading-spinner">
            <LoadingSpinner size={50} />
          </div>
        )
          :
          (
            <Button color={"orange"} disabled={!enableContactButton}>Contact us</Button>
          )}
      </div>
    </form>
  );
}

export default EmailForm;