import React from "react";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CardSlider.css";
import CourseCard from "./CourseCard";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import entityFormatter from "../../../helpers/entityFormatter";
const CardSlider = (props) => {
  let lang = props.lang;
  const entities = {
    "&#36;": "$"
  }
  let settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    rtl: (lang === "ar"),
    initialSlide: (lang === "ar") ? 2 : 0,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  }


  
  let courses;
  if (!props.loading && props.courses && props.courses.length > 0) {
    courses = props.courses.map((course) => (
      <div className="course-card" key={course.id}>
        <CourseCard
          id={course.id}
          lang={lang}
          title={course.name}
          instructor={course.custom_field_2}
          rating={course.rating}
          image={course.big_avatar}
          userCount={course.userCount}
          price={entityFormatter(course.price)}
        />
      </div>
    ))
  }
  else {
    courses = (
    <div className="home-slider-loading-spinner">
    <LoadingSpinner />
    </div>
    )
  }
  return (
    <div className={`card-slider ${lang === "ar" ? "rtl" : ""}`}>
      <Slider {...settings}>
        {courses}
      </Slider>
    </div>
  );
}

export default CardSlider;