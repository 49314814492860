import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner/LoadingSpinner";
import { useSelector, useDispatch } from "react-redux";
import { selectUserProfile, updateCachedCourses, updateCategoryCourses } from "../shared/reducers/userProfileSlice";
import categoryRatingGrabber from "../shared/helpers/categoryRatingGrabber";
import CourseList from "./CourseList";
import PageHeader from "../shared/components/UIElements/PageHeader/PageHeader";
import FilterButtons from "./FilterButtons";
const Category = (props) => {

  const userProfile = useSelector(selectUserProfile);
  let lang = userProfile.language;
  const dispatch = useDispatch();
  // let lang = props.lang;
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [title, setTitle] = useState();
  const [subcategories, setSubcategories] = useState([]);
  let { categoryid } = useParams();
  const history = useHistory();
  let originalCourses;

  const setCoursesHandler = (value) => {
    // console.log("setCoursesHandler fired")
    // console.log(value);
    // setLoading(true);
    setCourses(value);
    // setLoading(false);
  }


  // useEffect(() => {
  //   setCategory(props.match.params.categoryid);
  // }, [])

  // useEffect(() => {
  //   console.log("categoryid changed to", categoryid);
  // }, [categoryid])
  // useEffect(() => {
  //   setCategory(props.match.params.categoryid)
  // }, [props.match.params.categoryid])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    setCourses([]);
    // console.log(props.match.params);
  }, [props.match.params.categoryid, props.match.params.subcategoryid]);

  // useEffect(() => {
  //   let categoryIndex = userProfile.categories.findIndex((category) => category.id === categoryid);
  //   if(!categoryIndex){
  //     history.push("/");
  //     // mounted = false;
  //   }
  // }, [userProfile.categories])
  // useEffect(() => {
  //   console.log(props.match.params.subcategoryid);
  //   let filteredCourses = courses.filter((course) => course.subCategoryID === props.match.params.subcategoryid);
  //   if(props.match.params.subcategoryid){
  //     // setCourses(filteredCourses)
  //     console.log("coursesArray", filteredCourses);
  //     // console.log("filteredCourses", filteredCourses);
  //   }
  // }, [props.match.params.subcategoryid, courses])
  useEffect(() => {
    // setLoading(true);
    let mounted = true;

    const getCategoryDetails = async (categoryCourses) => {
      setLoading(true);

      // console.log("request fired");

      let categoryDetails = await categoryRatingGrabber(categoryCourses);

      dispatch(updateCategoryCourses({ parentID: categoryid, courses: categoryDetails }));
      dispatch(updateCachedCourses({ courses: categoryDetails }))

      setCourses(categoryDetails);
      setLoading(false);
    }
    // setCategory(props.match.params.categoryid);
    if (userProfile.categories.length > 0 && courses.length === 0) {
      try {
        let categoryIndex = userProfile.categories.findIndex((category) => category.id === categoryid);
        // if(!subCategories){
        //   mounted = false;
        //   // history.push("/");
        // }
        // console.log(categoryIndex);
        if (categoryIndex === -1) {

          throw new Error("404");
        }
        let subCategories = userProfile.categories[categoryIndex].subCategories;
        let categoryCourses = userProfile.categories[categoryIndex].courses;
        let categoryCoursesWithRatings = categoryCourses.filter((course) => course.rating >= 0);
        // let hasRatings = categoryCourses.some((course) => course.rating >= 0);
        let hasRatings = categoryCoursesWithRatings.length === categoryCourses.length;
        // let hasRatings = categoryCourses.some((course) => course.rating >= 0);
        setTitle(userProfile.categories[categoryIndex].name);
        // originalCourses = categoryCourses;
        setSubcategories(subCategories);
        if (!hasRatings) {
          getCategoryDetails(categoryCourses);

        }
        else {
          try {

            if (props.match.params.subcategoryid) {
              let subCategoryIndex = userProfile.categories[categoryIndex].subCategories.findIndex((subcategory) => subcategory.id === props.match.params.subcategoryid);
              console.log(subCategoryIndex);
              if (subCategoryIndex === -1) {
                throw new Error("500")
              }
              let subCategoryName = userProfile.categories[categoryIndex].subCategories[subCategoryIndex].name;
              // console.log(subCategoryName);
              setTitle(subCategoryName);

              let filteredCourses = [...categoryCourses];
              filteredCourses = filteredCourses.filter((course) => course.subCategoryID === props.match.params.subcategoryid);
              setCourses(filteredCourses);
              setLoading(false);
            }
            else {
              setCourses(categoryCourses);
              setSubcategories(subCategories);
              setLoading(false);
            }
          }
          catch (error) {
            if (error.message === "500") {
              props.match.params.subcategoryid = undefined;
            }
          }

          // console.log("condition met");
        }

      }
      catch (error) {
        if (error.message === "404") {
          history.push("/");
        }
      }

    }

    return () => {
      mounted = false;
    }

  }, [userProfile.categories, courses, props.match.params.categoryid, props.match.params.subcategoryid])
  return (
    <div className="category">

      {loading || courses.length === 0 ? (
        <div className="courses-loading-spinner"><LoadingSpinner /></div>
      )
        :
        (<React.Fragment>
          <PageHeader
            lang={lang}
            title={title}
            withFilter={!props.match.params.subcategoryid && true}
            subcategories={!props.match.params.subcategoryid && subcategories}
            withSort={true}
            courses={courses}
            setCoursesHandler={setCoursesHandler}
            parentCategoryID={props.match.params.categoryid}
          />
          <FilterButtons
            lang={lang}
            title={title}
            withFilter={!props.match.params.subcategoryid && true}
            subcategories={!props.match.params.subcategoryid && subcategories}
            withSort={true}
            courses={courses}
            setCoursesHandler={setCoursesHandler}
            parentCategoryID={props.match.params.categoryid}
          />
          <CourseList lang={lang} courses={courses} />
        </React.Fragment>
        )
      }
    </div>
  )
}

export default Category;