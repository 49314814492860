import React, { useState, useEffect, useRef } from "react";
import TextField from '@material-ui/core/TextField';
import { makeStyles, ThemeProvider, createMuiTheme, withTheme } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import grey from '@material-ui/core/colors/grey';
import Autocomplete from '@material-ui/lab/Autocomplete';
import "./SearchInput.css";
import courseGrabber from "../../../helpers/courseGrabber";
import RTL from "../RTL/RTL";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserProfile, setCachedCourses } from "../../../reducers/userProfileSlice";

const SearchInput = (props) => {
  const userProfile = useSelector(selectUserProfile);
  const cachedCourses = userProfile.cachedCourses;
  const [courses, setCourses] = useState(cachedCourses);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [initiateRequest, setInitiateRequest] = useState(false);
  const form = useRef();
  const history = useHistory();
  const top100Films = [
    // { title: 'The Shawshank Redemption', year: 1994 },
    // { title: 'The Godfather', year: 1972 },
    // { title: 'The Godfather: Part II', year: 1974 },
    // { title: 'The Dark Knight', year: 2008 },
    // { title: '12 Angry Men', year: 1957 },
    // { title: "Schindler's List", year: 1993 },
    // { title: 'Pulp Fiction', year: 1994 }
  ];
  // useEffect(() => {
  //   const getCourses = async () => {
  //     try {
  //       setLoading(true);
  //       setRequestCount((prev) => prev + 1);
  //       let options = {
  //         method: "get",
  //         url: "/api/v1/courses"
  //       }
  //       let response = await axios(options);
  //       let courseList = response.data;
  //       // console.log(response.data);
  //       // let courseList = response.data.filter((course) => course.hide_from_catalog === "0");
  //       // for (let course in courseList) {
  //       //   if (courseList[course].name.includes("|")) {
  //       //     let splitName = courseList[course].name.split("|");
  //       //     let name = {
  //       //       en: splitName[0],
  //       //       ar: splitName[1]
  //       //     }
  //       //     courseList[course].name = name;
  //       //   }
  //       // }
  //       setCourses(courseList);
  //       setCachedCourses({ courses: courseList })
  //       setLoading(false);
  //     }
  //     catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   if (courses.length === 0 && initiateRequest) {
  //     console.log("request activated")
  //     if (cachedCourses.length === 0) {

  //       getCourses();
  //     }
  //     else {
  //       setCourses([cachedCourses]);
  //     }
  //   }

  // }, [initiateRequest])

  useEffect(() => {
    setLoading(true)
    if (cachedCourses.length > 0) {
      setCourses(cachedCourses);
      setLoading(false);
    }
  }, [cachedCourses])
  let lang = props.lang;

  let labels;

  if (props.labels) {
    labels = { ...props.labels };
  }
  else {
    labels = {
      en: "Search",
      ar: "ابحث عن أي شيئ"
    };
  }

  const baseTheme = {
    typography: {
      "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
      "fontSize": 14,
      "fontWeightLight": 300,
      "fontWeightRegular": 400,
      "fontWeightMedium": 500
    },
    overrides: {
      MuiFormControl: {
        root: {
          color: "white",
          border: "0",
          margin: "0",
          marginTop: "0px",
          marginBottom: "0px",
          display: "inline-flex",
          padding: "0",
          position: "relative",
          minWidth: "0",
          flexDirection: "column",
          verticalAlign: "none"
        },
        marginNormal: {
          margin: "0",
          paddingTop: "40px",
        },
      },
      MuiAutocomplete: {
        inputRoot: {
          '& $input': {
            width: "auto",
          },
          '$hasPopupIcon &, $hasClearIcon &': {
            paddingRight: 0,
          },
        },
        hasClearIcon: {
          padding: "0%"
        },
        popper: {
          direction: "ltr",
          zIndex: "2001"
        },
        paper: {
          color: "white",
          backgroundColor: "black",
          boxShadow: "0 0 2px white"
        },
        option: {
          '&[data-focus="true"]': {
            backgroundColor: "rgb(255, 75, 18)",
          },
        },
        noOptions: {
          color: "white"
        },
        loading: {
          color: "white",
          padding: '14px 16px',
        },
      },
      MuiInputBase: {
        root: {
          color: "white"
        }
      },
    },
  };
  const themeLTR = createMuiTheme(baseTheme);

  const themeRTL = createMuiTheme({
    direction: "rtl",
    overrides: {
      ...baseTheme.overrides,
      MuiAutocomplete: {
        ...baseTheme.MuiAutocomplete,
        popper: {
          direction: "rtl"
        }
      }
    },
    ...baseTheme
  });


  const useStyles = makeStyles({
    root: {
      '& label.Mui-focused': {
        color: "white",
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        }
      }
    },
    input: {
      color: "white",
      '&::placeholder': {
        color: 'white'
      }
    },
    cssLabel: {
      color: 'white',
      '&.Mui-focused': {
        color: "white",
      }
    },
    underline: {
      borderBottom: '2px solid white',
      '&:after': {
        borderBottom: '2px solid white',
      },
    }
  });
  const optionLabelHandler = (course) => {
    let hasLangOptions = typeof course.name === 'object' && course.name !== null;
    if (hasLangOptions) {
      return course.name[lang];
    }
    else {
      // console.log("dropped SPAGHETTI")
      if (!course.name) {
        return course;
      }
      else {
        return course.name
      }
    }
  }
  const submitHandler = (e) => {
    e.preventDefault();
    // redirect to search
    checkOptions(input);
    if (input) {
      setSubmit(true);
    }
    else {
      setSubmit(false)
    }
  };

  const checkOptions = (value) => {
    // setInput(value)
    let filteredCourse = courses.filter((course) => optionLabelHandler(course) === value);
    let duplicatesRemoved = new Set(filteredCourse);
    if (duplicatesRemoved.size > 0) {
      setInput(...duplicatesRemoved);
    }
    else {
      setInput(value);
    }
  }

  const handleOptionSelect = (value) => {
    setInput(value);
    // setSubmit(true);
    // console.log(input);
  }

  useEffect(() => {
    if (input && input.id && submit) {
      // console.log(input);
      // console.log(input.id)
      history.push(`/course/${input.id}`);


      if (props.isMobile) {
        props.searchSubmitHandler();
      }
      setSubmit(false);
    }
    else if (input && !input.id && submit) {
      history.push(`/search?q=${input}`)
      if (props.isMobile) {
        props.searchSubmitHandler();
      }
      setSubmit(false);
    }
    else {
      setSubmit(false);
    }

  }, [input, submit]);

  const inputHandler = (value) => {
    // if (!initiateRequest) {
    //   setInitiateRequest(true);
    //   setInput(value)
    // }
    // else {
    setInput(value);
    // }
  }

  const buttonHandler = () => {
    setSubmit(true);
    // if (props.isMobile) {
    //   props.searchSubmitHandler();
    // }
  }
  const classes = useStyles();
  const LeftToRight = (
    <React.Fragment>
      <form className="search-input-form" onSubmit={submitHandler}>
        <ThemeProvider theme={themeLTR}>
          <Autocomplete
            fullWidth
            freeSolo
            options={courses}
            getOptionLabel={course => optionLabelHandler(course)}
            forcePopupIcon={false}
            onChange={(e, value) => handleOptionSelect(value)}
            onInputChange={(e, value) => inputHandler(value)}
            noOptionsText="No results"
            loading={loading}
            fullWidth
            renderInput={params => (
              <TextField
                {...params}
                id="standard-basic"
                fullWidth
                label={labels[lang]}
                fullWidth={props.fullWidth}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    root: classes.root,
                    input: classes.input,
                    underline: classes.underline
                  },
                  endAdornment: (
                    <InputAdornment>
                      <IconButton onClick={buttonHandler}>
                        <SearchIcon style={{ fill: "white" }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }} />
            )}
          />
        </ThemeProvider>
      </form>
    </React.Fragment>
  )


  const RightToLeft = (
    <React.Fragment>

      <RTL>
        <ThemeProvider theme={themeRTL}>
          <form className="search-input-form" onSubmit={submitHandler}>
            <span dir="rtl">
              <Autocomplete
                fullWidth
                freeSolo
                options={courses}
                getOptionLabel={course => optionLabelHandler(course)}
                forcePopupIcon={false}
                onChange={(e, value) => handleOptionSelect(value)}
                onInputChange={(e, value) => inputHandler(value)}
                noOptionsText="No results"
                loading={loading}
                fullWidth
                renderInput={params => (
                  <TextField
                    {...params}
                    id="standard-basic"
                    label={labels[lang]}
                    fullWidth={props.fullWidth}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        root: classes.root,
                        input: classes.input,
                        underline: classes.underline
                      },
                      endAdornment: (
                        <InputAdornment>
                          <IconButton onClick={buttonHandler}>
                            <SearchIcon style={{ fill: "white" }} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }} />
                )}
              />
            </span>
          </form>
        </ThemeProvider>
      </RTL>
    </React.Fragment>
  );

  if (props.lang === "ar") {
    return RightToLeft;
  }
  else {
    return LeftToRight;
  }


}

export default SearchInput;