import React, { useState } from "react";
import "./Banner.css";
import CountdownTimer from "./CountdownTimer";
import CloseIcon from '@material-ui/icons/Close';
const Banner = (props) => {
  let lang = props.lang;
  const [showBanner, setShowBanner] = useState(true);
  const labels = {
    bannerTitle: {
      en: "Start learning today! ",
      ar: "ابدأ التعلم اليوم!"
    },
    bannerDetails: {
      en: `Use the coupon code ${props.bannerData.coupons[0].Code} to get ${props.bannerData.coupons[0].Discount} off your next course!`,
      ar: `استخدم رمز القسيمة ${props.bannerData.coupons[0].Code} للحصول على دورات تدريبية بخصم ${props.bannerData.coupons[0].Discount}!`
    }
  };

  return (
    <div className={`banner`}>
      <div className="banner-container">
        <div className="banner-text">
          <div>

            {lang === "ar" ?
              (
                <span dir="rtl" className="banner-details">
                  <span className="banner-title"><bdi>{props.bannerData.announcement[lang]}</bdi> </span>
                  <bdi>{labels.bannerDetails[lang]}</bdi>
                </span>
              )
              :
              (
                <React.Fragment>
                  <span className="banner-title"> {props.bannerData.announcement[lang]}</span>
                  <span className="banner-details"> {labels.bannerDetails[lang]}</span>
                </React.Fragment>
              )
            }
            <div className="banner-timer">

              <CountdownTimer lang={lang} time={props.bannerData.coupons[0].To} />
            </div>
          </div>
        </div>
        <div className="close-icon-container">
          <CloseIcon className="close-icon" fontSize="large" onClick={() => props.bannerHandler()} />
        </div>
      </div>
    </div >
  )
}

export default Banner;