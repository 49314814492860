import React, { useState, useEffect } from "react";
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import entityFormatter from "../../../helpers/entityFormatter";
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import RTL from "../RTL/RTL";
const SortPopper = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      zIndex: 1001
    },
    paper: {
      boxShadow: "0 0 2px white",
      backgroundColor: "black",
      color: "white",
      padding: "5%",
      paddingRight: "5%",
      zIndex: "2000",
      whiteSpace: "nowrap",
      width: "100%",
    },
  }));

  const arabicStyles = makeStyles((theme) => ({
    root: {
      zIndex: 1001
    },
    paper: {
      boxShadow: "0 0 2px white",
      backgroundColor: "black",
      color: "white",
      padding: "5%",
      zIndex: "2000",
      whiteSpace: "nowrap",
      width: "100%",
      direction: "rtl"
    },
  }));

  const RadioStyles = makeStyles((theme) => ({
    root: {
      color: "rgb(255, 75, 18)",
      '&$checked': {
        color: "rgb(255, 75, 18)"
      }
    },
    checked: {}
  }));
  const [value, setValue] = useState(props.default || null);
  let lang = props.lang;
  let labels = {
    highToLow: {
      en: "From High to Low",
      ar: "من الأعلى إلى الأدنى"
    },
    lowToHigh: {
      en: "From Low to High",
      ar: "من الأدنى إلى الأعلى"
    }
  }
  const handleChange = (event) => {
    setValue(event.target.value);

    if (props.sortBy === "price") {
      sortByPrice(event.target.value);
    }
    else {
      sortByRating(event.target.value);
    }
    // props.updateSortSettings({[props.sortBy]: event.target.value});
    // console.log(props.sortBy)
  };

  const sortByPrice = (order) => {
    if (order === "ascending") {
      let courseList = [...props.courses];
      
      // console.log("sample sorted price", courseList[2][props.sortBy].split("&#36;")[1].replace(/,/g, ''));
      courseList.sort((a, b) => parseFloat(a[props.sortBy].split("&#36;")[1].replace(/,/g, '')) - parseFloat(b[props.sortBy].split("&#36;")[1].replace(/,/g, '')));
      props.setCoursesHandler([...courseList]);
    }
    else {
      let courseList = [...props.courses];

      courseList.sort((a, b) => parseFloat(b[props.sortBy].split("&#36;")[1].replace(/,/g, '')) - parseFloat(a[props.sortBy].split("&#36;")[1].replace(/,/g, '')));

      props.setCoursesHandler([...courseList]);
    }
  }

  const sortByRating = (order) => {
    if (order === "ascending") {
      let courseList = [...props.courses];
      // console.log(courseList[0][props.sortBy]);
      courseList.sort((a, b) => a[props.sortBy] - b[props.sortBy]);
      props.setCoursesHandler([...courseList]);
    }
    else {
      let courseList = [...props.courses];

      courseList.sort((a, b) => b[props.sortBy] - a[props.sortBy]);

      props.setCoursesHandler([...courseList]);
    }
  }

  // useEffect(() => {
  //   // console.log(props.courses);
  //   if(props.sortBy === "price"){
  //     sortByPrice(value);
  //   }
  //   else{
  //     sortByRating(value);
  //   }
  // }, [props.courses])

  // useEffect(() => {
  //   if(props.triggerSort){
  //     console.log("sort triggered");

  //     if(props.sortBy === "price" && value){
  //       sortByPrice(value)
  //     }
  //     else{
  //       sortByRating(value);
  //     }

  //   }
  // }, [props.triggerSort])

  useEffect(() => {
    if (props.filterCalled) {

      setValue(props.default || null);
    }
  }, [props.filterCalled])

  // useEffect(() =>{
  //   if(value){
  //     // if(props.sortBy === "rating" && value == "ase")
  //     console.log(value);
  //   }
  // }, [value, handleChange])
  const classes = useStyles();
  const radioClasses = RadioStyles();
  const arabicClasses = arabicStyles();
  const theme = createMuiTheme({
    direction: "rtl"
  });
  const sortRadiosLTR = (
    <div className={classes.paper}>
      <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
        <FormControlLabel value="descending" control={<Radio classes={{ root: radioClasses.root, checked: radioClasses.checked }} />} label={labels.highToLow[lang]} />
        <FormControlLabel value="ascending" control={<Radio classes={{ root: radioClasses.root, checked: radioClasses.checked }} />} label={labels.lowToHigh[lang]} />
      </RadioGroup>
    </div>
  );

  const sortRadiosRTL = (
    <RTL>
      <ThemeProvider theme={theme}>
        <div dir="rtl" className={arabicClasses.paper}>
          <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
            <FormControlLabel value="descending" control={<Radio classes={{ root: radioClasses.root, checked: radioClasses.checked }} />} label={labels.highToLow[lang]} />
            <FormControlLabel value="ascending" control={<Radio classes={{ root: radioClasses.root, checked: radioClasses.checked }} />} label={labels.lowToHigh[lang]} />
          </RadioGroup>
        </div>
      </ThemeProvider>
    </RTL>
  )
  return (
    <React.Fragment>
      <Popper id={props.id} className="higher-priority" open={props.open} anchorEl={props.anchorEl} placement={props.isMobile ? "bottom-end" : "bottom-start"} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div>
              {lang === "ar" ? sortRadiosRTL : sortRadiosLTR}
            </div>
          </Fade>
        )}
      </Popper>
    </React.Fragment>
  )
}

export default SortPopper;