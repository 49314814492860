import axios from "axios";

const categoryRatingGrabber = async (courses) => {
  let courseList = [...courses];
  for (let course in courseList) {
    let haveRating = courseList[course].rating >= 0;
    if (!haveRating) {
      // Get ratings for each course

      let options = {
        method: "get",
        url: `${process.env.REACT_APP_RATING_URL}/rating/${courseList[course].id}`,
      }
      let response = await axios(options);
      // console.log(response.data);

      courseList[course] = {
        ...courseList[course],
        rating: response.data.rating,
      }

    }
  }
  // sort by highest rating and set state
  courseList.sort((a, b) => b.rating - a.rating);
  return courseList;
}

export default categoryRatingGrabber;