import React from 'react';

import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import "./SuccessModal.css";
const SuccessModal = props => {
  return (
    <Modal
      onCancel={props.onClear}
      className="success-modal"
      headerClass="success-modal__header"
      show={!!props.success}
      footer={<Button color={"orange"} onClick={props.onClear}>Okay</Button>}
    >
      <p>{props.success}</p>
    </Modal>
  );
};

export default SuccessModal;
