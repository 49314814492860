import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import "./LoadingSpinner.css";
const LoadingSpinner = (props) => {
  
  return (
    <React.Fragment>
      <CircularProgress size={props.size || 100} />
    </React.Fragment>
  )
};

export default LoadingSpinner;