import React, { useEffect, useState } from "react";
import SearchInput from "../../shared/components/UIElements/SearchInput/SearchInput";
import "./Home.css";
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PeopleIcon from '@material-ui/icons/People';
import CardSlider from "../../shared/components/UIElements/CardSlider/CardSlider";
import CategorySelector from "../../shared/components/UIElements/CategorySelector/CategorySelector";
import { useSelector, useDispatch } from "react-redux";
import { selectUserProfile, toggleLanguage, setCachedCourses } from "../../shared/reducers/userProfileSlice";
import axios from "axios";
import courseGrabber from "../../shared/helpers/courseGrabber";
const Home = (props) => {
  // implement state caching later once you've figured out how to link everything in the different routes
  // this should save you a bunch of unnecessary GET requests
  const userProfile = useSelector(selectUserProfile);
  const dispatch = useDispatch();
  const [requestCount, setRequestCount] = useState(0);
  // const [courses, setCourses] = useState({
  //   "0": ["buttcheeks"],
  //   "1": ["ass"],
  //   "2": ["bunghole"],
  //   "3": ["dingleberry"]
  // });

  const [tabIndex, setTabIndex ] = useState(0)
  // const [firstTab, setFirstTab] = useState();
  // const [secondTab, setSecondTab] = useState();
  // const [thirdTab, setThirdTab] = useState();
  // const [fourthTab, setFourthTab] = useState();
  let lang = props.lang;
  let labels = {
    headline: {
      en: "What would you like to learn today?",
      ar: "ماذا تحب ان تتعلم؟"
    },
    whatWeDo: {
      col1: {
        en: "Choose from our wide selection of courses",
        ar: "ابحث عن أفضل المؤسسات التدريبية"
      },
      col2: {
        en: "Find the best instructor for you",
        ar: "ابحث عن أفضل مدرب لك"
      },
      col3: {
        en: "Find the best training institutions",

        ar: "ابحث عن أفضل المؤسسات التدريبية"
      }
    },
    descriptionTitle: {
      en: "Select from our wide collection of courses",
      ar: "اشترك في برامج تدريبية من مجموعتنا"
    },
    descriptionSubtitle: {
      en: "Start your success journey",
      ar: "ابدأ رحلة النجاح"
    }
  }

  

  // useEffect(() => {
  //   let mounted = true;
  //   const generateCourseSlider = async () => {
  //       if (userProfile.cachedCourses.length > 0) {
  //         if(mounted){
  //           setCourses(userProfile.cachedCourses);
  //         }
  //       }
  //       else {
  //         let courseList = await courseGrabber();
  //         if(mounted){
  //           setCourses(courseList);
  //           dispatch(setCachedCourses({ courses: courseList }))
  //         }
  //       }
  //   }
    
  //   generateCourseSlider();

  //   return () => {
  //     mounted = false;
  //   }
  // }, [])

  // const handleTabClick = (index, id) => {
  //   setTabIndex(index)
  //   console.log(courses[tabIndex])
  //   // if(id === 18){
  //   //   // send a request
  //   //   setBusinessCourses([])
  //   // }
  // }

  return (
    <div className="home">
      <div className="main-content">
        <div className="greeting-backdrop">
          <div className={`greeting-content ${props.lang === "en" ? "" : "rtl"}`}>
            <div className="greeting-headline">
              {labels.headline[lang]}
            </div>
            <div className="greeting-search-field">
              <SearchInput fullWidth={true} lang={lang} />
            </div>
          </div>
        </div>
        <div className="service-details">
          <div className={`what-we-do-container ${props.lang === "en" ? "" : "rtl"}`}>
            <div className={`what-we-do`}>
              <div className="what-we-do-col col-lt">

                <div className="what-we-do-col-icon">
                  <MenuBookIcon style={{ fontSize: '50px' }} />
                </div>
                <div className="what-we-do-col-title">
                  {labels.whatWeDo.col1[lang]}
                </div>
              </div>

              <div className="what-we-do-col col-cn">
                <div className="what-we-do-col-icon">
                  <PeopleIcon style={{ fontSize: '50px' }} />
                </div>
                <div className="what-we-do-col-title">
                  {labels.whatWeDo.col2[lang]}
                </div>
              </div>

              <div className="what-we-do-col col-rt">
                <div className="what-we-do-col-icon">
                  <AccountBalanceIcon style={{ fontSize: '50px' }} />
                </div>
                <div className="what-we-do-col-title">
                  {labels.whatWeDo.col3[lang]}
                </div>
              </div>
            </div>
          </div>
          <div className="browse-container">

            <div className={`courses-container ${props.lang === "en" ? "" : "rtl"}`}>

              <div className="section-description">
                <div className="section-description-content">
                  <div className="section-description-title">
                    {labels.descriptionTitle[lang]}
                  </div>
                  <div className="section-description-subtitle">
                    {labels.descriptionSubtitle[lang]}
                  </div>
                </div>
              </div>

              <div className="course-slider">
                <CategorySelector lang={lang} />
                {
                  // <CardSlider lang={lang} courses={courses} />

                }
              </div>
            </div>
          </div>
        </div>



      </div>


    </div>
  )
}

export default Home;