import React, { useState, useEffect, useRef } from "react";

import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUserProfile, addToCart, setUserCourses } from "../shared/reducers/userProfileSlice";
import UnitStepper from "../shared/components/UIElements/UnitStepper/UnitStepper";
import entityFormatter from "../shared/helpers/entityFormatter";
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Button from "../shared/components/UIElements/Button/Button";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import userCoursesGrabber from "../shared/helpers/userCoursesGrabber";
import userLogout from "../shared/helpers/userLogout";
import "./Course.css";
import CloseIcon from '@material-ui/icons/Close';
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner/LoadingSpinner";
import Cookies from "universal-cookie";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Modal from "../shared/components/UIElements/Modal/Modal";
import Authentication from "../shared/components/FormElements/Authentication/Authentication";
import ErrorModal from "../shared/components/UIElements/ErrorModal/ErrorModal";
import SuccessModal from "../shared/components/UIElements/SuccessModal/SuccessModal";
const Course = (props) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const userProfile = useSelector(selectUserProfile);
  // let lang = userProfile.language;
  const videoRef = useRef(null);
  let lang = userProfile.language;
  const [video, setVideo] = useState("");
  const [courseDetails, setCourseDetails] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSignUp, setShowSignup] = useState(false);
  const [error, setError] = useState("");
  const [showInstructorCourses, setShowInstructorCourses] = useState(false);
  const [success, setSuccess] = useState("");
  let { id } = useParams();
  const history = useHistory();
  console.log(id);
  const isAlreadyInCart = userProfile.cart.some(course => course.id === id);
  let title = {
    en: "Courses",
    ar: "البرامج التدريبية"
  }

  let labels = {
    courseOutline: {
      en: "Course Outline",
      ar: "محتويات البرنامج"
    },
    courseBy: {
      en: "By",
      ar: "بواسطة"
    },
    addToCartLabel: {
      en: "Add to cart",
      ar: "أضف إلى السلة"
    },
    goToCartLabel: {
      en: "Go to cart",
      ar: "اذهب الى السلة"
    },
    watchIntroVideo: {
      en: "Watch intro video",
      ar: "شاهد فيديو مقدمة"
    },
    ratingLabel: {
      en: "Rating",
      ar: "التقيم"
    },
    languageLabel: {
      en: "Language",
      ar: "لغة البرنامج"
    },
    priceLabel: {
      en: "Price",
      ar: "رسوم"
    },
    updatedLabel: {
      en: "Updated",
      ar: "محدث"
    },
    certificationDurationLabel: {
      en: "Certification duration",
      ar: "مدة الشهادة"
    },
    courseDescription: {
      en: "Course description",
      ar: "نبذة عن البرنامج"
    },
    aboutTheInstructor: {
      en: "About the instructor",
      ar: "عن المدرب"
    },
    moreCourseFromInstructorPreview: {
      en: "More courses from this instructor",
      ar: "المزيد من الدورات التدريبية من هذا المدرب"
    },
    moreCoursesFromInstructorButton: {
      en: "More courses from",
      ar: "المزيد من الدورات التدريبية من"
    },
    lessCoursesFromInstructorButton: {
      en: "Less courses from",
      ar: "دورات أقل من"
    },
    courseOwnedLabel: {
      en: "You already own this course",
      ar: "أنت تمتلك هذه الدورة بالفعل"
    },
    goToCourseLabel: {
      en: "Go to course",
      ar: "اذهب إلى الدورة"
    },
    signUp: {
      en: "Sign up",
      ar: "اشترك معنى"
    },
    courseInfoLabel: {
      en: "Course Info",
      ar: "معلومات الدورة"
    },
    englishLanguageLabel: {
      en: "English",
      ar: "انجليزي"
    },
    arabicLanguageLabel: {
      en: "Arabic",
      ar: "عربي"
    }
  }

  const playButtonHandler = () => {
    // console.log("play button handler clicked")
    setShowOverlay(false);
    videoRef.current.play();
  }

  const cartButtonHandler = () => {
    if (isAlreadyInCart) {
      // console.log("That course is already in your cart");
      history.push("/cart");
    }
    else {
      dispatch(addToCart(courseDetails));
    }
  }
  const closeButtonHandler = () => {
    setShowOverlay(true);
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
  };

  const errorModalHandler = () => {
    userLogout(dispatch);
  };

  const closeContactModalHandler = (message) => {
    setShowSignup(false);
    setSuccess(message);
  };

  const instructorCourseHandler = (id) => {
    // console.log("course id", id);
    history.push(`/course/${id}`)
  }

  const goToCourseButtonHandler = async () => {
    console.log(userProfile.loggedIn);
    if (userProfile.loggedIn && userProfile.userCourses.some(course => course.id === id)) {
      try {
        let options = {
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/gotocourse/course_id:${id}`,
        }

        let response = await axios(options);

        window.location.replace(response.data.goto_url)


      }
      catch (error) {
        if (error.response.data.error === "Authentication failed") {
          setError("Authentication failed. Please login and try again.");
        }
      }
    }
    else if (userProfile.loggedIn && !userProfile.userCourses.some(course => course.id === id)) {
      try {
        let options = {
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/addusertocourse`,
          data: {
            course_id: id
          },
          withCredentials: true
        }
        let response = await axios(options);
        try {
          let options = {
            method: "get",
            url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/gotocourse/course_id:${id}`,
            withCredentials: true
          }

          let response = await axios(options);

          window.location.replace(response.data.goto_url)


        }
        catch (error) {
          // console.log(error.response.data.error);
          if (error.response.data.error === "Authentication failed") {
            setError("Authentication failed. Please login and try again.");
          }
        }
      }
      catch (error) {
        // console.log(error.response.data.error);
        if (error.response.data.error === "Authentication failed") {
          setError("Authentication failed. Please login and try again.");
        }
      }


    }
    else {
      setShowSignup(true);
    }
  }


  // useEffect(() => {
  //   let mounted = true;
  //   const grabCourseInfo = async () => {
  //     let options = {
  //       method: 'get',
  //       url: `/api/v1/courses/id:${id}`
  //     };
  //     // get the general course info
  //     let response = await axios(options);

  //     // copy it to a variable for a batched state update
  //     let courseInfo = { ...response.data }

  //     // grab the rating for the course
  //     response = await axios.get(`/rating/${id}`);

  //     //append it to the copy
  //     courseInfo = { ...courseInfo, rating: response.data.rating }
  //     // find the instructor ID
  //     let instructorID = courseInfo.creator_id;
  //     options = {
  //       ...options,
  //       url: `/api/v1/users/id:${instructorID}`,
  //     };
  //     response = await axios(options);
  //     let instructorCourses = response.data.courses.filter((course) => course.role === "instructor");
  //     console.log(response.data.bio)
  //     courseInfo = {
  //       ...courseInfo,
  //       instructor: response.data.first_name + " " + response.data.last_name,
  //       instructorAvatar: response.data.avatar,
  //       instructorCourses,
  //       instructorBio: response.data.bio
  //     };

  //     response = await axios.get(`/video/${id}`);

  //     if (mounted) {
  //       setCourseDetails(courseInfo);
  //       setShowOverlay(true);
  //       setVideo(response.data.introVideo);
  //     }

  //     return () => {
  //       mounted = false;
  //     }
  //   }

  //   grabCourseInfo();

  //   return () => {
  //     mounted = false;
  //   }
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    // console.log(video)
  }, [video]);

  useEffect(() => {
    let mounted = true;
    setCourseDetails([]);
    setShowOverlay(false);
    setVideo("");
    window.scrollTo(0, 0);
    const grabCourseInfo = async () => {
      let options = {
        method: 'get',
        url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/id:${id}`
      };
      // get the general course info
      let response = await axios(options);

      // copy it to a variable for a batched state update
      let courseInfo = { ...response.data }

      if (courseInfo.name.includes("|")) {
        let splitName = courseInfo.name.split("|");
        let name = {
          en: splitName[0],
          ar: splitName[1]
        }
        courseInfo.name = name;
      }


      // grab the rating for the course
      response = await axios.get(`${process.env.REACT_APP_RATING_URL}/rating/${id}`);

      //append it to the copy
      courseInfo = { ...courseInfo, rating: response.data.rating }
      // find the instructor ID
      let filteredInstructors = courseInfo.users.filter((user) => user.role === "instructor" && user.name === courseInfo.custom_field_2);
      let instructorID = filteredInstructors[0].id;
      options = {
        ...options,
        url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/id:${instructorID}`,
      };
      response = await axios(options);
      let instructorCourses = response.data.courses.filter((course) => course.role === "instructor");
      // console.log(response.data.bio)
      courseInfo = {
        ...courseInfo,
        instructor: response.data.first_name + " " + response.data.last_name,
        instructorAvatar: response.data.avatar,
        instructorCourses,
        instructorBio: response.data.bio
      };
      // console.log(id);
      response = await axios.get(`${process.env.REACT_APP_RATING_URL}/video/${id}`);

      if (mounted) {
        setCourseDetails(courseInfo);
        setShowOverlay(true);
        setVideo(response.data.introVideo);
      }

      return () => {
        mounted = false;
      }
    }

    grabCourseInfo();

    return () => {
      mounted = false;
    }

  }, [props.match.params.id]);

  useEffect(() => {
    let mounted = true;
    const generateUserCourses = async () => {
      try {
        let userCourses = await userCoursesGrabber();
        if (mounted) {
          dispatch(setUserCourses({ courses: userCourses }));
        }
      }
      catch (error) {
        // console.log(error.message);
        if (userProfile.loggedIn) {
          if (mounted) {
            userLogout(dispatch);
          }
        }
      }
      //   }
    }
    if (userProfile.loggedIn) {
      generateUserCourses();
    }
    return () => {
      mounted = false;
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    const generateUserCourses = async () => {
      try {
        let userCourses = await userCoursesGrabber();
        if (mounted) {
          dispatch(setUserCourses({ courses: userCourses }));
        }
      }
      catch (error) {
        // console.log(error.message);
        if (userProfile.loggedIn) {
          if (mounted) {
            userLogout(dispatch);
          }
        }
      }
      //   }
    }
    if (userProfile.loggedIn && userProfile.userCourses.length === 0) {
      generateUserCourses();
    }

    return () => {
      mounted = false;
    }
  }, [userProfile.loggedIn])

  useEffect(() => {
    if (!video && courseDetails) {
      // console.log("there's no video, but everything else is here");
      setShowOverlay(true);
    }
  }, [video, courseDetails])

  let hasLangOptions = typeof courseDetails.name === 'object' && courseDetails.name !== null;
  const overlay = (

    <div className={`overlay ${lang === "ar" ? "rtl" : ""}`}>
      <div className="course-header">
        <div className="course-title">{hasLangOptions ? courseDetails.name[lang] : courseDetails.name}
          {
            (courseDetails.rating >= 0) && (
              <span className="course-rating">
                <Rating
                  name="read-only"
                  size="small"
                  value={courseDetails.rating}
                  precision={0.5}
                  readOnly
                  emptyIcon={<StarBorderIcon style={{ color: "white" }} fontSize="inherit" />}
                />
              </span>
            )
          }
          {userProfile.loggedIn && userProfile.userCourses.some(course => course.id === id) && (
            <span className="course-owned-transparent">
              {labels.courseOwnedLabel[lang]}
            </span>
          )}
        </div>
        <div className="course-instructor">
          <bdi>{labels.courseBy[lang]} {courseDetails.instructor}</bdi>
        </div>
        {courseDetails.price && (

          <div className="button-row">
            {courseDetails.price === "&#36;0.00" ? (
              <Button icon={<ExitToAppIcon />} color={"orange"} onClick={goToCourseButtonHandler}>{labels.goToCourseLabel[lang]}</Button>
            ) :
              (
                !userProfile.userCourses.some(course => course.id === id) && (
                  <Button lang={lang} icon={<ShoppingCartIcon />} color={"orange"} onClick={cartButtonHandler}>{isAlreadyInCart ? labels.goToCartLabel[lang] : labels.addToCartLabel[lang]}</Button>
                )
              )}
            {video && (
              <Button
                lang={lang}
                onClick={playButtonHandler}
                icon={<PlayArrowIcon style={{ transform: lang === "ar" ? "rotate(180deg)" : "none" }} />}
                color={"transparent"}>
                {labels.watchIntroVideo[lang]}
              </Button>
            )}
          </div>

        )}
      </div>
      <img className="course-thumbnail" src={courseDetails.big_avatar} />
    </div>
  );
  return (
    <div className="course">
      <Modal
        show={showSignUp}
        onCancel={() => setShowSignup(false)}
        headerClass={lang === "ar" ? "sign-up__modal-header-rtl" : ""}
        header={labels.signUp[lang]}
        contentClass="sign-up__modal-content"
      >
        <div>
          <Authentication
            lang={lang}
            signUpMode={true}
            contactMode={true}
            courseName={hasLangOptions ? courseDetails.name.en : courseDetails.name}
            closeModal={closeContactModalHandler}
          />
        </div>
      </Modal>
      <ErrorModal error={error} onClear={errorModalHandler} />
      <SuccessModal success={success} onClear={() => setSuccess(null)} />
      <div className={`course-details ${lang === "ar" ? "rtl" : ""}`}>
        <div className="video-container">
          {video && (
            <React.Fragment>
              <CSSTransition
                in={showOverlay}
                timeout={1000}
                classNames="my-node"
                mountOnEnter
                unmountOnExit
              >
                {overlay}
              </CSSTransition>
              <div className={`video-close-icon ${lang === "ar" ? "rtl" : ""}`} onClick={closeButtonHandler} >
                <CloseIcon fontSize="large" />
              </div>
              <video
                controls
                onContextMenu={(e) => e.preventDefault()}
                src={video}
                ref={videoRef}
                frameBorder={"0"}
                className="video"
                allowFullScreen={true}
                webkitallowfullscreen={"true"}
                mozallowfullscreen={"true"}
              />
            </React.Fragment>
          )}
          {!video && courseDetails.name && (
            <CSSTransition
              in={showOverlay}
              timeout={1000}
              classNames="my-node"
              mountOnEnter
              unmountOnExit
            >
              {overlay}
            </CSSTransition>
          )}
        </div>
        <div className="course-outline">
          <div className={`course-outline-label-container ${lang === "ar" ? "rtl" : ""}`}>
            <div className="course-outline-label">
              {labels.courseOutline[lang]}
            </div>
          </div>
          <UnitStepper lang={lang} units={courseDetails.units} />
        </div>
      </div>
      <div className={`course-description ${lang === "ar" ? "rtl" : ""}`}>
        <div className="course-stats">
          <div className="course-stats-header">{labels.courseInfoLabel[lang]}</div>
          {courseDetails.price && (
            <React.Fragment>
              <div className="course-stat-item">
                <div>
                  {labels.ratingLabel[lang]}
                </div>
                <div>
                  {(courseDetails.rating >= 0) && (
                    <Rating
                      name="read-only"
                      size="small"
                      defaultValue={0}
                      value={courseDetails.rating}
                      precision={0.5}
                      readOnly
                      emptyIcon={<StarBorderIcon style={{ color: "white" }} fontSize="inherit" />}
                    />
                  )}
                </div>
              </div>
              <div className="course-stat-item">
                <div>
                  {labels.priceLabel[lang]}
                </div>
                <div>
                  {entityFormatter(courseDetails.price)}
                </div>
              </div>
              <div className="course-stat-item">
                <div>
                  {labels.updatedLabel[lang]}
                </div>
                <div>
                  {courseDetails.last_update_on.split(",")[0]}
                </div>

              </div>
              <div className="course-stat-item">
                <div>
                  {labels.languageLabel[lang]}
                </div>
                <div>
                  {courseDetails.custom_field_3 === "Arabic" ? labels.arabicLanguageLabel[lang] : labels.englishLanguageLabel[lang]}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="course-divider-mobile-only"></div>
        <div className="course-description-body">
          <div className="course-description-header">
            {labels.courseDescription[lang]}
          </div>
          <div className="course-description-content">
            {courseDetails.description}
          </div>

        </div>

      </div>
      <div className="course-divider"></div>
      <div className={`course-description ${lang === "ar" ? "rtl" : ""}`}>
        <div className="instructor-name-and-avatar">
          <div className="instructor-avatar-container">
            <img className="instructor-avatar" src={courseDetails.instructorAvatar || "https://www.htmlcsscolor.com/preview/gallery/FF3400.png"} />
          </div>
          <div className="instructor-name">
            {courseDetails.instructor}
          </div>
        </div>
        <div className="course-description-body">
          <div className="course-description-header">
            {labels.aboutTheInstructor[lang]}
          </div>
          {
            // courseDetails.bio uncomment this later
          }
          <div className="course-description-content">

            {courseDetails.instructorBio}
          </div>
          <div className="instructor-courses">
            <div className="course-list-header">
              {labels.moreCourseFromInstructorPreview[lang]}
            </div>
            <div className="instructor-course-list">
              {courseDetails.instructorCourses && courseDetails.instructorCourses.map((course, index) => {
                let courseHasLangOptions = typeof course.name === 'object' && course.name !== null;
                if (course.id !== id) {

                  if (index >= 4) {
                    return (
                      showInstructorCourses && (
                        <div key={course.id} className="instructor-course-list-item" onClick={(e) => instructorCourseHandler(course.id)}>

                          {courseHasLangOptions ? entityFormatter(course.name[lang]) : entityFormatter(course.name)}

                        </div>

                      ))

                  }
                  else {
                    return (
                      <div key={course.id} className="instructor-course-list-item" onClick={(e) => instructorCourseHandler(course.id)}>
                        {courseHasLangOptions ? entityFormatter(course.name[lang]) : entityFormatter(course.name)}
                      </div>
                    )
                  }
                }
              }
              )}
            </div>
            <div className="more-courses-button">
              <Button color={"orange-transparent"} onClick={() => setShowInstructorCourses((prev) => !prev)} fullWidth={true}><bdi>{showInstructorCourses ? labels.lessCoursesFromInstructorButton[lang] : labels.moreCoursesFromInstructorButton[lang]} {courseDetails.instructor}</bdi></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Course;

