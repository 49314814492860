import React, { useState, useEffect } from "react";
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import CheckboxLabels from "./CheckboxLabels";
import SubcategoryCheckboxGroup from "./SubcategoryCheckboxGroup";
import Button from "../Button/Button";
import LanguageCheckboxGroup from "./LanguageCheckboxGroup";
import { useSelector } from "react-redux";
import { selectUserProfile } from "../../../reducers/userProfileSlice";
import ErrorModal from "../ErrorModal/ErrorModal";

import RTL from "../RTL/RTL";
const FilterPopper = (props) => {
  const userProfile = useSelector(selectUserProfile);
  let categoryIndex = userProfile.categories.findIndex((category) => category.id === props.parentCategoryID);
  let originalCourseList = userProfile.categories[categoryIndex].courses;
  let lang = props.lang;
  const [error, setError] = useState("");

  const useStyles = makeStyles((theme) => ({
    root: {
      zIndex: 1001
    },
    paper: {
      boxShadow: "0 0 2px white",
      backgroundColor: "black",
      padding: "5%",
      zIndex: "2000",
      whiteSpace: "nowrap",
      width: "100%"
    },
  }));
  const classes = useStyles();
  let subcategoryState = {};
  for (let subcategory in props.subcategories) {
    subcategoryState[props.subcategories[subcategory].id] = false
  }
  const [filter, setFilter] = useState({
    subcategories: subcategoryState,
    languages: {
      English: false,
      Arabic: false
    }
  });

  let labels = {
    doneButton: {
      en: "Done",
      ar: "منتهى"
    }
  }

  // const [originalCourseList, setOriginalCourseList] = useState([]);
  const setSubCategoryFilter = (subcategory) => {
    setFilter((prev) => ({ ...prev, subcategories: { ...prev.subcategories, ...subcategory } }));
  };
  const setLanguageFilter = (language) => {
    setFilter((prev) => ({ ...prev, languages: { ...prev.languages, ...language } }));
  }

  const getFilters = (obj) => {
    let filters = [];
    for (let key in obj) {
      if (obj[key] === true) {
        filters.push(key);
      }
    }

    return filters;
  }
  // console.log(subcategories);
  // const sortByPrice = (order, courses) => {
  //   let courseList = [...courses];
  //   if (order === "ascending") {
  //     courseList.sort((a, b) => parseFloat(a.price.slice(5)) - parseFloat(b.price.slice(5)));
  //   }
  //   else {
  //     courseList.sort((a, b) => parseFloat(b.price.slice(5)) - parseFloat(a.price.slice(5)));
  //   }
  //   return courseList;
  // };

  // const sortByRating = (order, courses) => {
  //   let courseList = [...courses];
  //   if (order === "ascending") {
  //     courseList = courseList.sort((a, b) => a.rating - b.rating);
  //   }
  //   else {
  //     courseList = courseList.sort((a, b) => b.rating - a.rating);
  //   }
  //   return courseList;
  // };

  // const sortHandler = (courses) => {
  //   let result;

  //   if (props.sortSettings.price && props.sortSettings.rating) {

  //     let courseList = sortByPrice(props.sortSettings.price, courses);
  //     result = sortByRating(props.sortSettings.rating, courseList);
  //     return result;
  //   }
  //   else {

  //     result = sortByRating(props.sortSettings.rating, courses);
  //     return result;
  //   }





  //   // return result;
  // }
  const checkFilterResults = (courseList) => {
    if (courseList.length > 0) {
      props.setCoursesHandler([...courseList]);
    }
    else {
      setError("Couldn't find any courses under those filters.")
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let subcategoriesToFilter = getFilters(filter.subcategories);
    let languagesToFilter = getFilters(filter.languages);



    if (subcategoriesToFilter.length > 0 && languagesToFilter.length > 0) {

      let courseList = [...originalCourseList];
      courseList = courseList.filter(course => subcategoriesToFilter.some(category => category === course.subCategoryID));
      courseList = courseList.filter(course => languagesToFilter.some(language => language === course.custom_field_3));
      // console.log(courseList);
      checkFilterResults(courseList)
    }
    else if (subcategoriesToFilter.length > 0) {
      let courseList = [...originalCourseList];
      courseList = courseList.filter(course => subcategoriesToFilter.some(category => category === course.subCategoryID));
      // let result = sortHandler(courseList)
      props.setCoursesHandler([...courseList]);

    }
    else if (languagesToFilter.length > 0) {
      let courseList = [...originalCourseList];
      courseList = courseList.filter(course => languagesToFilter.some(language => language === course.custom_field_3));
      checkFilterResults(courseList);
    }
    else {
      // let courseList = [...props.courses];
      let courseList = [...originalCourseList];
      // let result = sortHandler(courseList)
      props.setCoursesHandler([...courseList]);
    }
    // if(subcategoriesToFilter.length === 0 && languagesToFilter === 0){
    //   let courseList = [...originalCourseList];
    //   props.setCoursesHandler([...courseList]);
    // }
    props.updateFilterCalled();
    // props.sortCourses();

  }



  // let PopperLTR = (
  //   <Popper id={props.id} className="higher-priority" open={props.open} anchorEl={props.anchorEl} placement={lang === "ar" ? "bottom-end" : "bottom-start"} transition>
  //     {({ TransitionProps }) => (
  //       <Fade {...TransitionProps} timeout={350}>
  //         <form className={classes.paper} onSubmit={handleSubmit}>
  //           <div className="filter-checkbox-row">
  //             <SubcategoryCheckboxGroup lang={props.lang} subcategoryState={filter.subcategories} subcategories={props.subcategories} setSubCategoryFilter={setSubCategoryFilter} />
  //             {

  //               // <div className="filter-button"><Button color={"orange-transparent"}>Done</Button></div>
  //             }
  //             <LanguageCheckboxGroup lang={props.lang} languageState={filter.languages} setLanguageFilter={setLanguageFilter} />

  //           </div>
  //           <div>
  //             <div className="filter-submit-button"><Button color={"orange-transparent"}>Done</Button></div>
  //           </div>
  //         </form>
  //       </Fade>
  //     )}
  //   </Popper>
  // );


  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={() => setError(null)} />
      <Popper id={props.id} className="higher-priority" open={props.open} anchorEl={props.anchorEl} placement={lang === "ar" ? "bottom-end" : "bottom-start"} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <form className={classes.paper} onSubmit={handleSubmit}>
            <div className="filter-checkbox-row">
              <SubcategoryCheckboxGroup lang={props.lang} subcategoryState={filter.subcategories} subcategories={props.subcategories} setSubCategoryFilter={setSubCategoryFilter} />
              {

                // <div className="filter-button"><Button color={"orange-transparent"}>Done</Button></div>
              }
              <LanguageCheckboxGroup lang={props.lang} languageState={filter.languages} setLanguageFilter={setLanguageFilter} />

            </div>
            <div>
              <div className={`filter-submit-button ${lang === "ar" ? "rtl" : ""}`}><Button color={"orange-transparent"}>{labels.doneButton[lang]}</Button></div>
            </div>
          </form>
        </Fade>
      )}
    </Popper>
    </React.Fragment>
  )
}

export default FilterPopper;