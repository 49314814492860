import React, { useState, useEffect, useRef } from "react";
import PageHeader from "../shared/components/UIElements/PageHeader/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { selectUserProfile, setUserCourses } from "../shared/reducers/userProfileSlice";
import Button from "../shared/components/UIElements/Button/Button";
import { useHistory } from "react-router-dom";
import "./Cart.css";
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone';
import CartTable from "./CartTable";
import Modal from "../shared/components/UIElements/Modal/Modal";
import Authentication from "../shared/components/FormElements/Authentication/Authentication";
import Cookies from "universal-cookie";
import userCoursesGrabber from "../shared/helpers/userCoursesGrabber";
import userLogout from "../shared/helpers/userLogout";
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import CouponSelector from "./CouponSelector";
const Cart = (props) => {
  let lang = props.lang;
  const [initialCart, setInitialCart] = useState(null);
  const userProfile = useSelector(selectUserProfile);
  const dispatch = useDispatch();
  const [showLogin, setShowLogin] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const history = useHistory();
  const [showAuth, setShowAuth] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [coupon, setCoupon] = useState(userProfile.coupons[0]);
  const cookies = new Cookies();
  let title = {
    en: "Cart",
    ar: "عربة التسوق"
  }

  let labels = {
    emptyCartText: {
      en: "Look like you cart is still empty. Why not take a look around?",
      ar: ".عربة التسوق فارغة. استمر في التسوق للعثور على دورة"
    },
    emptyCartButton: {
      en: "Continue shopping",
      ar: "استمر في التسوق"
    },
    courseCounter: {
      en: `You have ${userProfile.cart.length} courses in your cart`,
      ar: ` لديك ${userProfile.cart.length} دورات في سلة التسوق`
    },
    login: {
      en: "Login",
      ar: "دخول"
    },
    subtotal: {
      en: "Subtotal",
      ar: "المجموع الفرعي"
    },
    checkoutButton: {
      en: "Checkout",
      ar: "الدفع"
    },
    proceedToPayment: {
      en: "Proceed to payment",
      ar: "الشروع في دفع"
    },
    authChoices: {
      en: "Please login or sign up to continue:",
      ar: "يرجى تسجيل الدخول أو الاشتراك للمتابعة"
    },
    signUp: {
      en: "Sign up",
      ar: "اشترك معنى"
    },
    offLabel: {
      en: "off",
      ar: "خصم"
    }
  }
  const oldCart = [...userProfile.cart];

  const selectCouponHandler = (selectedCoupon) => {
    setCoupon(selectedCoupon);
  }

  useEffect(() => {
    // console.log("initial cart set");
    setInitialCart(userProfile.cart.length);
  }, []);

  useEffect(() => {
    // console.log("initial coupon", coupon);
  }, [coupon]);

  useEffect(() => {
    if (userProfile.coupons.length > 0 && !coupon) {
      // console.log("headerCoupon", new Date(userProfile.coupons[0].To) > new Date());
      if (new Date(userProfile.coupons[0].To) > new Date()) {
        // console.log("happy path met");
        setCoupon(userProfile.coupons[0]);
      }
      else {
        // console.log("else condition met")
        setCoupon(null)
      }
    }
    else if (userProfile.coupons.length > 0 && coupon) {
      if (new Date(coupon.To) < new Date()) {
        setCoupon(null);
      }
    }
  }, [userProfile.coupons, coupon])

  useEffect(() => {
    if (userProfile.loggedIn) {
      if (initialCart > userProfile.cart.length && userProfile.cart.length === 0) {
        setWarningMessage("Looks like you already own those courses. As such, they were removed from your cart.")
      }
      else if (initialCart > userProfile.cart.length && userProfile.cart.length > 0) {
        setWarningMessage("You already own some of those courses. They were removed from your cart and the balance has been changed to reflect that.")
      }
    }
  }, [userProfile.cart, userProfile.loggedIn])



  const emptyCartButtonHandler = async () => {
    history.push("/");
  }

  const cartChangeHandler = (newLength) => {
    // console.log(userProfile.cart.length)
    setInitialCart(newLength - 1)
  }

  // useEffect(() => {

  // })

  // useEffect(() => {

  // }, [userProfile.cart.length])
  const discountCalculator = (originalPrice, discount) => {
    if (originalPrice.startsWith("$")) {
      originalPrice = originalPrice.split("$")[1].replace(/,/g, '');
      discount = parseFloat(discount);
      // console.log(discount)
      let discountedPrice = originalPrice - (originalPrice * discount / 100);
      return discountedPrice.toFixed(2);
    }
    else {
      // console.log(originalPrice);
      discount = parseFloat(discount);
      // console.log(discount)
      let discountedPrice = originalPrice - (originalPrice * discount / 100);
      return discountedPrice.toFixed(2);

    }
  };

  const discountOnlyCalculator = (originalPrice, discount) => {
    if (originalPrice.startsWith("$")) {
      originalPrice = originalPrice.split("$")[1].replace(/,/g, '');
      discount = parseFloat(discount);
      // console.log(discount)
      let discountedPrice = (originalPrice * discount / 100);
      return discountedPrice.toFixed(2);
    }
    else {
      // console.log(originalPrice);
      discount = parseFloat(discount);
      // console.log(discount)
      let discountedPrice = (originalPrice * discount / 100);
      return discountedPrice.toFixed(2);

    }
  }

  const subtotalCalculator = (cart) => {

    let subtotal = 0.00;
    for (let course in cart) {

      let individualPrice = cart[course].price.split("&#36;")[1].replace(/,/g, '');
      // console.log(parseFloat(individualPrice);
      individualPrice = parseFloat(individualPrice);
      subtotal += individualPrice;
    }
    // if (coupon) {
    //   console.log(discountCalculator(subtotal, coupon.Discount));
    //   return subtotal.toFixed(2);
    // }
    // else {
    return subtotal.toFixed(2);

    // }
  }

  const checkoutHandler = async () => {
    if (!userProfile.loggedIn) {
      setShowAuth(true);
    }
    else {
      if (coupon) {
        history.push("/payment", {
          cart: userProfile.cart,
          subtotal: discountCalculator(subtotalCalculator(userProfile.cart), coupon.Discount),
          discount: discountOnlyCalculator(subtotalCalculator(userProfile.cart), coupon.Discount),
          originalPrice: subtotalCalculator(userProfile.cart),
          coupon: coupon.Code
        })
      }
      else {
        history.push("/payment", {
          cart: userProfile.cart,
          subtotal: subtotalCalculator(userProfile.cart),
          discount: 0.00,
          originalPrice: subtotalCalculator(userProfile.cart),
        })
      }

    }
  }


  const authLoginButtonHandler = () => {
    setShowAuth(false);
    setShowLogin(true)
  }

  const authSignUpButtonHandler = () => {
    setShowAuth(false);
    setShowSignUp(true);
  }

  return (
    <div className="cart">
      <PageHeader lang={lang} title={title} />
      <Modal
        show={showLogin}
        headerClass={props.lang === "ar" ? "sign-up__modal-header-rtl" : ""}
        onCancel={() => setShowLogin(false)}
        header={labels.login[lang]}
        contentClass="sign-up__modal-content"
      >
        <div>
          <Authentication lang={lang} closeModal={() => setShowLogin(false)} />
        </div>
      </Modal>
      <Modal
        show={showSignUp}
        onCancel={() => setShowSignUp(false)}
        headerClass={props.lang === "ar" ? "sign-up__modal-header-rtl" : ""}
        header={labels.signUp[lang]}
        contentClass="sign-up__modal-content"
      >
        <div>
          <Authentication lang={lang} signUpMode={true} closeModal={() => setShowSignUp(false)} />
        </div>
      </Modal>
      <Modal
        show={showAuth}
        headerClass={props.lang === "ar" ? "sign-up__modal-header-rtl" : ""}
        onCancel={() => setShowAuth(false)}
        header={lang === "ar" ? "تفويض" : "Authorize"}
        contentClass="sign-up__modal-content"
      >
        <div className="auth-choices">
          {labels.authChoices[lang]}
          <div className="auth-choice-buttons">
            <Button color={"orange-transparent"} onClick={authLoginButtonHandler}>{labels.login[lang]}</Button>
            <Button color={"orange-transparent"} onClick={authSignUpButtonHandler}>{labels.signUp[lang]}</Button>
          </div>
        </div>
      </Modal>

      <div className={`cart-main-content ${lang === "ar" ? "rtl" : ""}`}>
        <div className={`cart-details ${userProfile.cart.length === 0 ? "fullwidth" : ""}`}>
          {warningMessage && (
            <div className="warning-message">
              <div className="warning-content">
                <div className="warning-icon">
                  <WarningIcon style={{ fontSize: "40px" }} />
                </div>
                <div className="warning-text">
                  {warningMessage}
                </div>
              </div>
              <div className="warning-close-button" onClick={() => setWarningMessage("")}>
                <CloseIcon />
              </div>
            </div>
          )}
          <div className={`course-counter ${lang === "ar" ? "rtl" : ""}`}>
            {labels.courseCounter[lang]}
          </div>

          {(userProfile.cart.length > 0) ?
            (

              <CartTable lang={lang} cart={userProfile.cart} cartChangeHandler={cartChangeHandler} coupon={coupon} discountCalculator={discountCalculator} />

            )
            :
            (
              <div className="empty-cart">
                <ShoppingCartTwoToneIcon style={{ fontSize: '200px', color: "black" }} />
                <div className="empty-cart-text">
                  {labels.emptyCartText[lang]}
                </div>

                <Button color={"orange"} onClick={emptyCartButtonHandler}>{labels.emptyCartButton[lang]}</Button>
              </div>
            )
          }
        </div>

        {(userProfile.cart.length > 0) && (
          <div className="checkout">
            <div className="subtotal">
              {labels.subtotal[lang]}
            </div>
            {coupon ?
              (
                <React.Fragment>
                  <div className="subtotal-price">
                    ${discountCalculator(subtotalCalculator(userProfile.cart), coupon.Discount)}
                  </div>
                  <div className="original-price-and-discount">
                    <div className="original-price">
                      ${subtotalCalculator(userProfile.cart)}
                    </div>
                    <div>
                      {coupon.Discount} {labels.offLabel[lang]}
                    </div>
                  </div>
                </React.Fragment>
              )
              :
              (
                <div className="subtotal-price">
                  ${ subtotalCalculator(userProfile.cart)}
                </div>

              )}
            <div className="checkout-button">
              <Button onClick={checkoutHandler} color={"orange-transparent"}>{userProfile.loggedIn ? labels.proceedToPayment[lang] : labels.checkoutButton[lang]}</Button>
            </div>
            <CouponSelector lang={lang} couponList={userProfile.coupons} selectCouponHandler={selectCouponHandler} />
            {coupon && (
              <div className="checkout-coupon-code">
                <span className="current-coupon-code">{coupon.Code}</span> is applied
              </div>
            )}
          </div>

        )}

      </div>

    </div>
  )
}

export default Cart;