import React, { useState } from "react";
import TextField from '@material-ui/core/TextField';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Button from "../shared/components/UIElements/Button/Button";
import "./CouponSelector.css";
import RTL from "../shared/components/UIElements/RTL/RTL";
const CouponSelector = (props) => {
  let lang = props.lang;
  const [error, setError] = useState(false);

  const [couponField, setCouponField] = useState("");

  let labels = {
    couponLabel: {
      en: "Coupon code",
      ar: "رمز الكوبون"
    },
    couponSubmitLabel: {
      en: "Apply",
      ar: "طبق"
    },
    invalidCouponLabel: {
      en: "Invalid Coupon",
      ar: "قسيمة غير صالحة"
    }
  }
  const useStyles = makeStyles({
    root: {
      borderRadius: 0,
    
      '& label.Mui-focused': {
        color: "white",
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        }
      }
    },
    input: {
      color: "white",
      '&::placeholder': {
        color: 'white'
      }
    },
    cssLabel: {
      color: 'white',
      '&.Mui-focused': {
        color: "white",
      }
    },

    notchedOutline: {
      borderWidth: '1px',
      borderColor: 'rgb(255, 75, 18) !important'
    }
  });

  const baseTheme = {
    typography: {
      "fontFamily": `"Helvetica", "Arial", sans-serif`,
      "fontSize": 14,
      "fontWeightLight": 300,
      "fontWeightRegular": 400,
      "fontWeightMedium": 500
    },
    overrides: {
      MuiFormControl: {
        root: {
          color: "white",
          border: "0",
          margin: "0",
          marginTop: "0px",
          marginBottom: "0px",
          display: "inline-flex",
          padding: "0",
          position: "relative",
          minWidth: "0",
          flexDirection: "column",
          verticalAlign: "none"
        },
        marginNormal: {
          margin: "0",
          paddingTop: "40px",
        },
      },
      MuiInputBase: {
        root: {
          color: "white"
        }
      },
      MuiAutocomplete: {
        inputRoot: {
          '& $input': {
            width: "auto",
          },
          '$hasPopupIcon &, $hasClearIcon &': {
            paddingRight: 0,
          },
        },
        hasClearIcon: {
          padding: "0%"
        },
        popper: {
          direction: "ltr",
          zIndex: "20004",
          position: "relative",
          top: "50px"

        },
        popperDisablePortal: {

        },
        paper: {
          color: "white",
          backgroundColor: "black",
          boxShadow: "0 0 2px white"
        },
        option: {
          '&[data-focus="true"]': {
            backgroundColor: "rgb(255, 75, 18)",
          },
        },
        noOptions: {
          color: "white"
        },
        loading: {
          color: "white",
          padding: '14px 16px',
        },
      },
    },
  };
  const classes = useStyles();
  const themeLTR = createMuiTheme(baseTheme);

  const themeRTL = createMuiTheme({ direction: "rtl", ...baseTheme });

  const handleSubmit = (e) => {
    e.preventDefault();
    let availableCoupons = props.couponList;
    let foundCoupons = availableCoupons.filter((coupon) => coupon.Code === couponField);

    if (foundCoupons.length === 0) {
      setError(true)
    }
    else {
      props.selectCouponHandler(foundCoupons[0]);
      setError(false);
    }

  }

  let CouponSelectorLTR = (
    <form onSubmit={handleSubmit}>
      <ThemeProvider theme={themeLTR}>
        <div className={`coupon-selector ${lang === "ar" ? "rtl" : ""}`}>
          <div className="coupon-selector-field">

            <TextField

              variant="outlined"
              label={labels.couponLabel[lang]}
              fullWidth={true}
              onChange={(e) => setCouponField(e.target.value)}
              placeholder={labels.couponLabel[lang]}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.root,
                  input: classes.input,
                  underline: classes.underline,
                  notchedOutline: classes.notchedOutline,
                }
              }
              }
            />
          </div>
          <div className="coupon-selector-submit-button">
            <Button color={"orange-transparent"}>{labels.couponSubmitLabel[lang]}</Button>
          </div>

        </div>
        {error && (
          <div className="coupon-selector-error">{labels.invalidCouponLabel[lang]}</div>
        )}
      </ThemeProvider>
    </form>
  );

  let CouponSelectorRTL = (
    <form onSubmit={handleSubmit}>
      <div className={`coupon-selector ${lang === "ar" ? "rtl" : ""}`}>
        <div className="coupon-selector-field">

          <RTL>
            <ThemeProvider theme={themeRTL}>
              <span dir="rtl">
                <TextField

                  variant="outlined"
                  label={labels.couponLabel[lang]}
                  fullWidth={true}
                  onChange={(e) => setCouponField(e.target.value)}
                  placeholder={labels.couponLabel[lang]}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.root,
                      input: classes.input,
                      underline: classes.underline,
                      notchedOutline: classes.notchedOutline,
                    }
                  }
                  }
                />
              </span>
            </ThemeProvider>
          </RTL>
        </div>
        <div className="coupon-selector-submit-button">
          <Button color={"orange-transparent"}>{labels.couponSubmitLabel[lang]}</Button>
        </div>

      </div>
      {error && (
        <div className="coupon-selector-error">{labels.invalidCouponLabel[lang]}</div>
      )}
    </form>
  );

  return lang === "ar" ? CouponSelectorRTL : CouponSelectorLTR;
};

export default CouponSelector;