import React, { useState, useEffect } from "react";
import "./CourseList.css";
import { useSelector, useDispatch } from "react-redux";
import { selectUserProfile, setCachedCourses, setUserCourses, setLoggedIn } from "../shared/reducers/userProfileSlice";
import CourseItem from "./CourseItem";
import courseGrabber from "../shared/helpers/courseGrabber";
import userCourseGrabber from "../shared/helpers/courseGrabber";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner/LoadingSpinner";
// import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import entityFormatter from "../shared/helpers/entityFormatter";
import userCoursesGrabber from "../shared/helpers/userCoursesGrabber";
import Cookies from "universal-cookie";
import userLogout from "../shared/helpers/userLogout";
const CourseList = (props) => {
  let lang = props.lang;
  const cookies = new Cookies();
  const userProfile = useSelector(selectUserProfile);
  // const cachedCourses = userProfile.cachedCourses;
  const [loading, setLoading] = useState(false);
  // const [courses, setCourses] = useState(cachedCourses);
  const [courses, setCourses] = useState(props.courses);
  const dispatch = useDispatch();
  let renderedCourses = (courses.map((course) => (
    <CourseItem
      key={course.id}
      id={course.id}
      title={course.name}
      description={course.description}
      image={course.big_avatar}
      instructor={course.custom_field_2}
      price={entityFormatter(course.price)}
      rating={course.rating}
      userCount={course.userCount}
      lang={lang}
      courseDetails={{ ...course }}
    />

  )));

  useEffect(() => {
  setCourses(props.courses);
  }, [props.courses])
  // useEffect(() => {
  //   let mounted = true;
  //   const generateCourseList = async () => {
  //     setLoading(true);
  //     let courseList = await courseGrabber();
  //     if (mounted) {
  //       // dispatch(setCachedCourses({ courses: courseList }));
  //       setCourses(courseList);
  //       setLoading(false);
  //     }
  //   };
  //   if (courses.length === 0) {
  //     if (cachedCourses.length === 0) {
  //       generateCourseList();
  //     }
  //     else {
  //       setCourses([cachedCourses]);
  //     }
  //   }
  //   return () => {
  //     mounted = false;
  //   }
  // }, []);

  // useEffect(() => {
  //   setLoading(true);
  //   if(cachedCourses.length > 0){
  //     setCourses(cachedCourses);
  //     setLoading(false);
  //   }
  // })

  // handled for on component mount
  // useEffect(() => {
  //   let mounted = true;
  //   const generateUserCourses = async () => {
  //     // if (!loading) {
  //     //   setLoading(true);
  //     //   let userCourses = await userCoursesGrabber();
  //     //   dispatch(setUserCourses({ courses: userCourses }));
  //     //   setLoading(false);
  //     // }
  //     // else {
  //     try {
  //       let userCourses = await userCoursesGrabber(cookies.get("session"));
  //       if (mounted) {
  //         dispatch(setUserCourses({ courses: userCourses }));
  //       }
  //     }
  //     catch (error) {
  //       console.log(error.message);
  //       if (userProfile.loggedIn) {
  //         if (mounted) {
  //           userLogout(dispatch);
  //         }
  //       }
  //     }
  //     //   }
  //   }
  //   if (userProfile.loggedIn) {
  //     generateUserCourses();
  //   }

  //   return () => {
  //     mounted = false;
  //   }
  // }, []);
  

  useEffect(() => {
    let mounted = true;
    const generateUserCourses = async () => {
      // if (!loading) {
      //   setLoading(true);
      //   let userCourses = await userCoursesGrabber();
      //   dispatch(setUserCourses({ courses: userCourses }));
      //   setLoading(false);
      // }
      // else {
      try {
        let userCourses = await userCoursesGrabber(cookies.get("session"));
        if (mounted) {
          dispatch(setUserCourses({ courses: userCourses }));
        }
      }
      catch (error) {
        // console.log(error.message);
        if (userProfile.loggedIn) {
          if (mounted) {
            userLogout(dispatch);
          }
        }
      }
      //   }
    }
    if (userProfile.loggedIn) {
      generateUserCourses();
    }

    return () => {
      mounted = false;
    }
  }, [userProfile.loggedIn])
  // {loading ?
  //   (<div className="courses-loading-spinner"><LoadingSpinner /></div>) : renderedCourses}
  return (
    <div className="course-list">
      {renderedCourses}
    </div>)
}

export default CourseList;