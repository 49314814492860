import React, { useState, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import "./Authentication.css"
import formUrlEncoded from "../../../helpers/formUrlEncoded";
import Button from "../../UIElements/Button/Button";
import axios from "axios";
import Cookies from "universal-cookie";
import ErrorModal from "../../UIElements/ErrorModal/ErrorModal";
import { useDispatch, useSelector } from "react-redux";
import { selectUserProfile, setLoggedIn, setUserCourses, setCart } from "../../../reducers/userProfileSlice";
import userCoursesGrabber from "../../../helpers/userCoursesGrabber";
import RTL from "../../UIElements/RTL/RTL";
// import Cookies from "universal-cookie";
import Autocomplete from '@material-ui/lab/Autocomplete';
import countries from "./countries";
import LoadingSpinner from "../../UIElements/LoadingSpinner/LoadingSpinner";
import OrangeCheckbox from "../../UIElements/PageHeader/OrangeCheckbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Link } from "react-router-dom";

const Authentication = (props) => {
  // console.log("countries from file", countries);
  let signUpMode = props.signUpMode;
  let contactMode = props.contactMode;
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const userProfile = useSelector(selectUserProfile);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState();
  // const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState("");
  const [gender, setGender] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const genders = ["Male", "Female", "Other"];


  const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 }
  ];
  // let enableButton = firstName && lastName && (email.length > 7) && (username.length > 6) && (password.length > 6);
  let labels = {
    username: {
      en: "Username",
      ar: "اسم المستخدم"
    },
    password: {
      en: "Password",
      ar: "كلمه السر"
    },
    email: {
      en: "Email",
      ar: "البريد الالكتروني"
    },
    formFirstName: {
      en: "First Name",
      ar: "الاسم الاول"
    },
    formLastName: {
      en: "Last Name",
      ar: "اسم العائلة"
    },
    formCountry: {
      en: "Country",
      ar: "بلد الإقامة"
    },
    formDOB: {
      en: "Date of birth",
      ar: "تاريخ الميلاد"
    },
    formGender: {
      en: "Gender",
      ar: "الجنس"
    },
    forgotPasswordLabel: {
      en: "Forgot your password?",
      ar: "نسيت كلمتك السرية؟"
    },
    termsCheckboxAgreementLabel: {
      en: "I agree to the",
      ar: "أوافق على"
    },
    termsCheckboxLinkLabel: {
      en: "terms and conditions",
      ar: "الشروط والأحكام"
    }
  };
  // const isValidEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValidEmail = /\S+@\S+\.\S+/;
  let enableSignUpButton = (username.length > 0) && (password.length > 8) && country && gender && selectedDate && (firstName.length > 0) && (lastName.length > 0) && (isValidEmail.test(email)) && termsChecked;
  let enableContactButton = country && gender && selectedDate && (firstName.length > 0) && (lastName.length > 0) && (isValidEmail.test(email)) && termsChecked;

  let enableLoginButton = (username.length > 0) && (password.length > 8);
  useEffect(() => {
    if (userProfile.userCourses.length > 0 && userProfile.cart.length > 0) {
      const filteredCart = userProfile.cart.filter(cart => !userProfile.userCourses.some(course => course.id === cart.id));
      dispatch(setCart(filteredCart));
    }
  }, [userProfile.userCourses]);

  // useEffect(() => {
  //   const getCountries = async () => {
  //     console.log("request fired");
  //     let options = {
  //       method: "get",
  //       url: "/api/v1/countries"
  //     }
  //     let response = await axios(options);
  //     // console.log("countries", response.data);
  //     setCountryList(response.data);
  //   }

  //   getCountries();
  // }, []);

  // const handleOptionSelect = (value) => {
  //   setCountry(value);
  // };

  const dateHandler = (e) => {
    // console.log(e.target.value.replace(/-/g, '/'));
    let formattedDate = e.target.value.replace(/-/g, '/');
    setSelectedDate(formattedDate);
  };

  const forgotPasswordHandler = async () => {
    // console.log("bung");
    // console.log(username)
    try {
      let options = {
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/forgotpassword/username:${username}`,
      }
      let response = await axios(options);
      props.closeModal(response.data.message);
    }
    catch (error) {
      setError(error.response.data.error);
    }
  };

  const termsCheckboxHandler = (event) => {
    setTermsChecked(event.target.checked);
  }
  const submitHandler = async (e) => {
    e.preventDefault();
    // console.log("form submitted");
    // setError(null);
    if (signUpMode && contactMode) {
      setLoading(true);
      const data = {
        first_name: firstName,
        last_name: lastName,
        email,
        login: username,
        password,
        custom_field_3: country,
        custom_field_4: selectedDate,
        custom_field_5: gender,
        courseName: props.courseName
      }
      try {
        let options = {
          method: 'POST',
          url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/contactus`,
          data,
        };
        let response = await axios(options);
        // console.log(response.data);
        // if(!error){
        props.closeModal(response.data.message);
        setLoading(false);
        // }
      }
      catch (error) {
        setLoading(false);
        // console.log(error.response.data.error);
        setError(error.response.data.error);
      }
      // if(response.data.login_key){
      //   // let options = {
      //   //   method: "GET",
      //   //   url: `api/v1/users/id:${response.data.login_key}`
      //   // }
      // }
    }
    else if (signUpMode && !contactMode) {
      const data = {
        first_name: firstName,
        last_name: lastName,
        email,
        login: username,
        password,
        custom_field_3: country,
        custom_field_4: selectedDate,
        custom_field_5: gender
      }
      try {
        let options = {
          method: 'POST',
          url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/usersignup`,
          data,
          withCredentials: true
        };
        let response = await axios(options);
        // console.log(response.data);
        // if(!error){
        dispatch(setLoggedIn(true));
        props.closeModal(response.data.message);
        // }
      }
      catch (error) {
        // console.log(error.response.data.error);
        setError(error.response.data.error);
      }
      // console.log("normal sign up");
    }
    else {
      try {
        const data = {
          login: username,
          password,
        }
        let options = {
          method: 'POST',
          url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/userlogin`,
          data,
          withCredentials: true
        };
        let response = await axios(options);


        // console.log(response.data);

        // cookies.set("token", response.data.token, { path: "/" });
        // cookies.set("redirect", response.data.redirect, { path: '/' });
        // cookies.set("session", response.data.session, { path: "/" });


        let userCourses = await userCoursesGrabber();
        dispatch(setUserCourses({ courses: userCourses }));
        // console.log(response.data);


        dispatch(setLoggedIn(true));
        props.closeModal(response.data.message);
      }
      catch (error) {
        // console.log(error);
        // console.log(error.message)
        setError(error.response.data.error);
        // console.log(error.response.data.error);
      }
    }
  }
  const useStyles = makeStyles({
    root: {
      marginBottom: "5%",
      '& label.Mui-focused': {
        color: "white",
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        }
      }
    },
    input: {
      color: "white",
      '&::placeholder': {
        color: 'white'
      }
    },
    cssLabel: {
      color: 'white',
      '&.Mui-focused': {
        color: "white",
      }
    },

    notchedOutline: {
      borderWidth: '1px',
      borderColor: 'rgb(255, 75, 18) !important'
    }
  });

  const baseTheme = {
    typography: {
      "fontFamily": `"Helvetica", "Arial", sans-serif`,
      "fontSize": 14,
      "fontWeightLight": 300,
      "fontWeightRegular": 400,
      "fontWeightMedium": 500
    },
    overrides: {
      MuiFormControl: {
        root: {
          color: "white",
          border: "0",
          margin: "0",
          marginTop: "0px",
          marginBottom: "0px",
          display: "inline-flex",
          padding: "0",
          position: "relative",
          minWidth: "0",
          flexDirection: "column",
          verticalAlign: "none"
        },
        marginNormal: {
          margin: "0",
          paddingTop: "40px",
        },
      },
      MuiInputBase: {
        root: {
          color: "white"
        }
      },
      MuiAutocomplete: {
        inputRoot: {
          '& $input': {
            width: "auto",
          },
          '$hasPopupIcon &, $hasClearIcon &': {
            paddingRight: 0,
          },
        },
        hasClearIcon: {
          padding: "0%"
        },
        popper: {
          direction: "ltr",
          zIndex: "20004",
          position: "relative",
          top: "50px"

        },
        popperDisablePortal: {

        },
        paper: {
          color: "white",
          backgroundColor: "black",
          boxShadow: "0 0 2px white"
        },
        option: {
          '&[data-focus="true"]': {
            backgroundColor: "rgb(255, 75, 18)",
          },
        },
        noOptions: {
          color: "white"
        },
        loading: {
          color: "white",
          padding: '14px 16px',
        },
      },
    },
  };
  const classes = useStyles();
  const themeLTR = createMuiTheme(baseTheme);

  const themeRTL = createMuiTheme({ direction: "rtl", ...baseTheme });

  let lang = props.lang;

  let signUpForm = (
    <React.Fragment>
      <div className="name-inputs">
        <div className="first-name">
          <TextField

            variant="outlined"
            label={labels.formFirstName[lang]}
            fullWidth={true}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder={labels.formFirstName[lang]}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.root,
                input: classes.input,
                underline: classes.underline,
                notchedOutline: classes.notchedOutline,
              }
            }
            }
          />
        </div>
        <div className="last-name">
          <TextField
            fullWidth={true}
            variant="outlined"
            label={labels.formLastName[lang]}
            placeholder={labels.formLastName[lang]}
            onChange={(e) => setLastName(e.target.value)}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.root,
                input: classes.input,
                underline: classes.underline,
                notchedOutline: classes.notchedOutline,
              }
            }
            }
          />
        </div>
      </div>

      <div className="email-and-gender-container">
        <div className={`email ${lang === "ar" ? "rtl" : ""}`}>
          <TextField

            variant="outlined"
            label={labels.email[lang]}
            placeholder={labels.email[lang]}
            fullWidth={true}
            onChange={(e) => setEmail(e.target.value)}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.root,
                input: classes.input,
                underline: classes.underline,
                notchedOutline: classes.notchedOutline,
              }
            }
            }
          />
        </div>
        <div className="gender-container">
          <div className="gender">
            <Autocomplete
              disablePortal
              disableListWrap
              options={genders}
              onChange={(e, value) => setGender(value)}
              getOptionLabel={(option) => option}

              renderInput={(params) => (

                <TextField
                  {...params}
                  variant="outlined"
                  label={labels.formGender[lang]}
                  placeholder={labels.formGender[lang]}
                  fullWidth={true}

                  InputLabelProps={{
                    ...params.InputLabelProps,
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      root: classes.root,
                      input: classes.input,
                      underline: classes.underline,
                      notchedOutline: classes.notchedOutline,
                    }
                  }
                  }
                />
              )
              }
            />

          </div>
        </div>
      </div>

      <div className="country-and-date-container">

        <div className="country-field-container">
          <div className="country">
            <Autocomplete
              disablePortal
              disableListWrap
              options={countries}
              onChange={(e, value) => setCountry(value)}
              getOptionLabel={(option) => option}

              renderInput={(params) => (

                <TextField
                  {...params}
                  variant="outlined"
                  label={labels.formCountry[lang]}
                  placeholder={labels.formCountry[lang]}
                  fullWidth={true}

                  InputLabelProps={{
                    ...params.InputLabelProps,
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      root: classes.root,
                      input: classes.input,
                      underline: classes.underline,
                      notchedOutline: classes.notchedOutline,
                    }
                  }
                  }
                />
              )
              }
            />
          </div>
        </div>

        <div className={`date-container ${lang === "ar" ? "rtl" : ""}`}>
          <TextField
            fullWidth
            variant="outlined"
            id="date"
            format="yyyy/MM/dd"
            label={labels.formDOB[lang]}
            type="date"
            defaultValue="1990-01-01"
            onChange={dateHandler}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.root,
                input: classes.input,
                underline: classes.underline,
                notchedOutline: classes.notchedOutline,
              }
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
  let loginForm = (
    <React.Fragment>

      <TextField

        variant="outlined"
        label={labels.username[lang]}
        placeholder={labels.username[lang]}
        onChange={(e) => setUsername(e.target.value)}
        fullWidth={true}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.root,
            input: classes.input,
            underline: classes.underline,
            notchedOutline: classes.notchedOutline,
          }
        }
        }
      />
      <TextField

        variant="outlined"
        label={labels.password[lang]}
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        placeholder={labels.password[lang]}
        fullWidth={true}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.root,
            input: classes.input,
            underline: classes.underline,
            notchedOutline: classes.notchedOutline,
          }
        }
        }
      />
    </React.Fragment>
  )
  let RightToLeft = (
    <React.Fragment>
      <RTL>
        <ThemeProvider theme={themeRTL}>
          <div dir="rtl" className="authentication">
            {props.signUpMode && signUpForm}
            {!props.contactMode && loginForm}
            {!props.signUpMode && username && (
              <div className="forgot-password" onClick={forgotPasswordHandler}>
                {labels.forgotPasswordLabel[lang]}
              </div>
            )}
            {props.signUpMode && signUpForm && loginForm && (
              <div className="terms-and-conditions-checkbox">
                <FormControlLabel
                  checked={termsChecked}
                  onChange={termsCheckboxHandler}
                  control={<OrangeCheckbox />}
                  label={<span className="terms-checkbox-label">{labels.termsCheckboxAgreementLabel[lang]} <Link to="/terms" onClick={() => props.closeModal("")}>{labels.termsCheckboxLinkLabel[lang]}</Link></span>}
                />
              </div>
            )}
          </div>
        </ThemeProvider>
      </RTL>
    </React.Fragment>);

  let LeftToRight = (
    <React.Fragment>

      <ThemeProvider theme={themeLTR}>
        <div dir="ltr" className="authentication">
          {props.signUpMode && signUpForm}
          {!props.contactMode && loginForm}
          {!props.signUpMode && username && (
            <div className="forgot-password" onClick={forgotPasswordHandler}>
              {labels.forgotPasswordLabel[lang]}
            </div>
          )}
          {props.signUpMode && signUpForm && loginForm && (
            <div className="terms-and-conditions-checkbox">
              <FormControlLabel
                checked={termsChecked}
                onChange={termsCheckboxHandler}
                control={<OrangeCheckbox />}
                label={<span className="terms-checkbox-label">{labels.termsCheckboxAgreementLabel[lang]} <Link to="/terms" onClick={() => props.closeModal("")}>{labels.termsCheckboxLinkLabel[lang]}</Link></span>}
              />
            </div>
          )}
        </div>
      </ThemeProvider>
    </React.Fragment>)

  return (
    <form onSubmit={submitHandler}>
      <ErrorModal error={error} onClear={() => setError(null)} />
      {lang === "en" ? LeftToRight : RightToLeft}
      <div className="authentication-footer">
        {props.signUpMode ?
          (
            (props.contactMode ? (
              (loading ? (
                <div className="payment-loading-spinner">
                  <LoadingSpinner size={50} />
                </div>
              )
                :
                (
                  <Button color={"orange"} disabled={!enableContactButton}>Contact us</Button>
                ))

            ) :
              (
                <Button color={"orange"} disabled={!enableSignUpButton}>Sign up</Button>
              ))

          )
          :
          (
            <Button color={"orange"} disabled={!enableLoginButton}>Login</Button>

          )
        }
      </div>
    </form>

  )

}

export default Authentication;