import React, { useEffect, useState } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OrangeCheckbox from "../shared/components/UIElements/PageHeader/OrangeCheckbox";
import SidebarLanguageCheckboxes from './SidebarLanguageCheckboxes';
import ErrorModal from "../shared/components/UIElements/ErrorModal/ErrorModal";
import RTL from "../shared/components/UIElements/RTL/RTL";
const CheckboxTree = (props) => {
  let lang = props.lang;
  const [tree, setTree] = useState(props.categoryList);
  const [languageState, setLanguageState] = useState(props.availableLanguages);
  const [error, setError] = useState("");
  //  originalCourseList = userProfile.categories[categoryIndex].courses;
  const [originalCourseList, setOriginalCourseList] = useState([...props.courses]);
  let labels = {
    languageOptions: {
      en: "Course languages",
      ar: "لغات الدورة"
    }
  }
  // useEffect(() => {
  //   setTree(props.categoryList);
  // })
  useEffect(() => {
    console.log("checkboxTree mounted");
    // // let res = props.availableLanguages.reduce((a, b) => (a[b] = true), {});
    // const arr = ['a', 'b', 'c'];
    // let res = props.availableLanguages.reduce((a, b) => (a[b] = true, a), {});
    // console.log(res)
    // console.log("converte Language state", res)
    // console.log(props.availableLanguages);
  }, [])

  const setLanguageFilter = (key, value) => {
    // console.log("language key", key)
    // console.log("language value", value);
    setLanguageState((prev) => ({ ...prev, [key]: value }));

  }

  // useEffect(() => {
  //   console.log("language state", languageState)
  // }, [languageState])
  useEffect(() => {
    // console.log("location changed")
    setOriginalCourseList([...props.courses]);
    setTree(props.categoryList);
  }, [props.location])

  const handleChange = (event, parent_category_id, checked) => {
    event.stopPropagation();
    // console.log(event.target.name);
    // console.log(parent_category_id);
    // console.log(checked);
    let treeCopy = [...tree];
    if (!parent_category_id) {
      let parentCheckboxIndex = treeCopy.findIndex((category) => category.id === event.target.name);
      // console.log(tree[parentCheckboxIndex]);

      let childNodes = treeCopy[parentCheckboxIndex].subCategories.map((child) => ({
        ...child,
        checked: !treeCopy[parentCheckboxIndex].checked
      }));

      // console.log(childNodes)

      let targetedParentCheckbox = {
        ...treeCopy[parentCheckboxIndex],
        checked: !treeCopy[parentCheckboxIndex].checked,
        subCategories: childNodes
      };

      treeCopy[parentCheckboxIndex] = targetedParentCheckbox;
      // console.log(treeCopy)
      setTree(treeCopy);
    }
    else {
      let parentCheckboxIndex = treeCopy.findIndex((category) => category.id === parent_category_id);
      let childCheckboxIndex = treeCopy[parentCheckboxIndex].subCategories.findIndex((subcategory) => subcategory.id === event.target.name);
      // treeCopy[parentCheckboxIndex].checked = true;
      treeCopy[parentCheckboxIndex].subCategories[childCheckboxIndex].checked = !treeCopy[parentCheckboxIndex].subCategories[childCheckboxIndex].checked;
      // console.log(treeCopy);
      setTree(treeCopy);
    }
    // setState({ ...state, [event.target.name]: event.target.checked });
    // if(event.target.checked){
    // props.setLanguageFilter({ [event.target.name]: event.target.checked });
    // }
  };

  useEffect(() => {
    console.log("tree changed", tree);
    let filteredCategories = [];
    let filteredParentCategories = tree.filter((category) => category.checked === true).map((category) => category.id);
    filteredCategories.push(...filteredParentCategories)
    for (let branch in tree) {
      let filteredSubCategories = tree[branch].subCategories.filter((subcategory) => subcategory.checked === true).map((subcategory) => subcategory.id);
      filteredCategories.push(...filteredSubCategories);
    };
    // console.log(filteredCategories);
    // let filteredCourses = filteredCategories.filter
    // console.log("originalCourseList", courseList);
    let filteredLanguages = [];
    for (let language in languageState) {
      if (languageState[language].checked === true) {
        filteredLanguages.push(language);
      }
    }

    // console.log(filteredLanguages);
    if (filteredLanguages.length > 0 && filteredCategories.length > 0) {
      // let courseList = [...originalCourseList];
      // courseList = courseList.filter(course => filteredLanguages.some(language => language === course.custom_field_3));
      // courseList = courseList.filter(course => filteredCategories.some(category => category === course.subCategoryID || category === course.parent_category_id));

      let courseList = originalCourseList.filter(course => filteredLanguages.some(language => language === course.custom_field_3));
      courseList = courseList.filter(course => filteredCategories.some(category => category === course.subCategoryID || category === course.parent_category_id));
      if (courseList.length > 0) {
        props.setCoursesHandler(courseList)
        props.courseSortHandler(courseList);
      }
      else {
        setError("Couldn't find any courses under those filters");
      }

      // props.setCoursesHandler(courseList)
    }
    else if (filteredCategories.length > 0) {
      // let courseList = [...originalCourseList];
      // courseList = courseList.filter(course => filteredCategories.some(category => category === course.subCategoryID || category === course.parent_category_id));

      let courseList = originalCourseList.filter(course => filteredCategories.some(category => category === course.subCategoryID || category === course.parent_category_id));

      props.setCoursesHandler(courseList);
      props.courseSortHandler(courseList);
    }
    else if (filteredLanguages.length > 0) {
      // let courseList = [...originalCourseList];
      // courseList = courseList.filter(course => filteredLanguages.some(language => language === course.custom_field_3));

      let courseList = originalCourseList.filter(course => filteredLanguages.some(language => language === course.custom_field_3));

      props.setCoursesHandler(courseList);
      props.courseSortHandler(courseList);
    }
    else {
      // let courseList = [...originalCourseList];
      // props.setCoursesHandler(courseList);
      // props.courseSortHandler(courseList);

      props.setCoursesHandler(originalCourseList);
      props.courseSortHandler(originalCourseList);
    }
    // props.setCoursesHandler
  }, [tree, languageState])

  const useStyles = makeStyles({
    root: {
      height: 110,
      display: "inline-block",
      marginBottom: "10%"
    },
  });
  const classes = useStyles();
  const renderTree = (nodes) => (
    <TreeItem key={`checkbox-${nodes.id}`} nodeId={nodes.id} label={
      <FormControlLabel
        style={{ marginLeft: "1%" }}
        control={<OrangeCheckbox
          checked={nodes.checked}
          name={nodes.id}
          onChange={(event) => handleChange(event, nodes.parent_category_id, nodes.checked)}
          onClick={e => (e.stopPropagation())}
        />}
        label={nodes.name[lang]}
      />}>
      {Array.isArray(nodes.subCategories) ? nodes.subCategories.map((node) => renderTree(node)) : null}
    </TreeItem>
  );

  let TreeViewLTR = (
    <React.Fragment>
      <ErrorModal error={error} onClear={() => setError(null)} />
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={tree.map(branch => branch.id)}
        defaultExpandIcon={<ChevronRightIcon />}

      >

        {tree.map((rootNode) => (
          renderTree(rootNode)
        ))}
      </TreeView>
      <div className="filter-sidebar-header">
        Course languages
      </div>
      <SidebarLanguageCheckboxes lang={lang} languageState={languageState} setLanguageFilter={setLanguageFilter} />
    </React.Fragment>
  );

  const theme = createMuiTheme({
    direction: "rtl"
  });

  let TreeViewRTL = (
    <RTL>
      <ThemeProvider theme={theme}>
        <span dir="rtl">
          <ErrorModal error={error} onClear={() => setError(null)} />
          <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={tree.map(branch => branch.id)}
            defaultExpandIcon={<ChevronRightIcon />}

          >

            {tree.map((rootNode) => (
              renderTree(rootNode)
            ))}
          </TreeView>
          <div className="filter-sidebar-header rtl">
            {labels.languageOptions[lang]}
          </div>
          <SidebarLanguageCheckboxes lang={lang} languageState={languageState} setLanguageFilter={setLanguageFilter} />
        </span>
      </ThemeProvider>
    </RTL>
  )
  return lang === "ar" ? TreeViewRTL : TreeViewLTR;
};

export default CheckboxTree;