import React, { useState, useEffect } from "react";
import CourseList from "../Courses/CourseList";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserProfile, updateCachedCourses, updateCategoryCourses } from "../shared/reducers/userProfileSlice";
import coursesRatingGrabber from "../shared/helpers/coursesRatingGrabber";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner/LoadingSpinner";
import "./Search.css";
import HelpIcon from '@material-ui/icons/Help';
import CheckboxTree from "./CheckboxTree";

import TuneIcon from '@material-ui/icons/Tune';
import Button from "../shared/components/UIElements/Button/Button";
import PageHeader from "../shared/components/UIElements/PageHeader/PageHeader";
import SidebarLanguageCheckboxes from "./SidebarLanguageCheckboxes";
import LanguageCheckboxGroup from "../shared/components/UIElements/PageHeader/LanguageCheckboxGroup";
import SortDropdown from "./SortDropdown";
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloseIcon from '@material-ui/icons/Close';

const Search = (props) => {
  // let lang = props.lang;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [showFilter, setShowFilter] = useState(false);
  const [treeMemory, setTreeMemory] = useState([]);
  let title = {
    en: "Search",
    ar: "بحث"
  }

  let labels = {
    categories: {
      en: "Categories and subcategories",
      ar: "الفئات والفئات الفرعية"
    },
    filterButton: {
      en: "FILTER",
      ar: "فلتر"
    },
  }

  const drawerStyles = makeStyles({
    root: {
      zIndex: 2001
    },
    paper: {
      backgroundColor: "rgb(22, 22, 22)",
      color: "white",
      width: "320px"
    }
  });


  const drawerClasses = drawerStyles();

  const userProfile = useSelector(selectUserProfile);
  const dispatch = useDispatch();
  let lang = userProfile.language;
  // let categories

  const searchString = query.get("q").toLowerCase();
  // const searchString = query.get("q");
  // console.log(searchString);
  // console.log(props);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [coursesToSort, setCoursesToSort] = useState([]);
  const setCoursesHandler = (value) => {
    // console.log("setCoursesHandler fired")
    // console.log(value);
    // setLoading(true);
    // setLoading(false);

    setCourses(value);

  }

  const courseSortHandler = (value) => {
    setCoursesToSort(value);
  }

  // const saveTreeMemoryHandler = (currentTree) => {
  //   setTreeMemory(currentTree);
  // }


  // const drawerClasses = drawerStyles();

  useEffect(() => {
    // setCourses([]);
    window.scrollTo(0, 0)
    // console.log("location changed")
    setLoading(true);
  }, [props.location.search]);

  const getCategoryAndSubCategoryNames = () => {
    let names = [];
    let categories = userProfile.categories;
    for (let category in categories) {
      names.push({ id: categories[category].id, name: categories[category].name });
      let subcategories = categories[category].subCategories.map((subcategory) => ({ id: subcategory.id, name: subcategory.name }));
      names.push(...subcategories);
    }
    // console.log(names);
    return names;
  };

  const getRelevantCategoriesAndSubCategories = (results) => {
    let parentIDs = [...new Set(results.map(course => course.parent_category_id))];
    let subcategories = [...new Set(results.map(course => course.subCategoryID))];
    // console.log("Retrieved ParentID", parentIDs);
    // console.log("Retrieved SubcategoryIDs", subcategories);
    let categories = userProfile.categories;
    let relevantCategories = [];
    for (let id of parentIDs) {
      let currentParentIndex = categories.findIndex(category => category.id === id);
      let currentParentCategory = {
        id: categories[currentParentIndex].id,
        name: categories[currentParentIndex].name,
        subCategories: [],
        courses: [],
        checked: false
      };
      let relevantSubcategories = categories[currentParentIndex].subCategories.filter((subcategory) => subcategories
        .some((filteredSubcategory) => filteredSubcategory === subcategory.id))
        .map((subcategory) => ({ ...subcategory, checked: false }));
      // console.log(relevantSubcategories);
      let relevantCourses = results.filter((course) => subcategories.some((subcategory) => course.subCategoryID === subcategory));
      // console.log("relevant categories", relevantCourses);
      currentParentCategory.courses.push(...relevantCourses);
      currentParentCategory.subCategories.push(...relevantSubcategories);
      relevantCategories.push(currentParentCategory);
    }

    // console.log(relevantCategories);
    return relevantCategories;
  }
  useEffect(() => {
    const getCourseDetails = async (categoryCourses) => {
      setLoading(true);

      // console.log("request fired");

      let categoryDetails = await coursesRatingGrabber(categoryCourses);
      // console.log(categoryDetails);

      dispatch(updateCachedCourses({ courses: categoryDetails }));

      let relevantCategories = getRelevantCategoriesAndSubCategories(categoryDetails);

      for (let category in relevantCategories) {
        let parentID = relevantCategories[category].id;
        let categoryCourses = relevantCategories[category].courses;
        // console.log("parentID in loop", parentID);
        // console.log("category courses in loop", categoryCourses)
        dispatch(updateCategoryCourses({ parentID, courses: categoryCourses }));
      }


      let filteredLanguages = new Set(categoryDetails.filter(course => course.custom_field_3 !== null).map(course => course.custom_field_3));
      // console.log(filteredLanguages);
      setAvailableLanguages({
        English: {
          checked: false,
          disabled: !filteredLanguages.has("English")
        },
        Arabic: {
          checked: false,
          disabled: !filteredLanguages.has("Arabic")
        }
      });

      setCategoryList(relevantCategories);
      setCourses(categoryDetails);
      setLoading(false);
    }

    if (userProfile.cachedCourses.length > 0 && loading) {
      // console.log("condiiton met");
      // console.log(getCategoryAndSubCategoryNames());
      let fetchedCategories = getCategoryAndSubCategoryNames();
      let searchedCoursesByTitle = userProfile.cachedCourses.filter((course) => {
        let hasLangOptions = typeof course.name === 'object' && course.name !== null;
        if (hasLangOptions) {
          // consider changing it back to this if something screws up
          // course.name[lang].toLowerCase().includes(searchString)

          if (course.name.en.toLowerCase().includes(searchString) || course.name.en.toLowerCase().includes(searchString)) {
            return course;
          }
        }
        else {
          if (course.name.toLowerCase().includes(searchString)) {
            return course;
          }
        }
      });

      // consider changing it back to this if something screws up
      // category.name[lang].toLowerCase().includes(searchString)
      let matchedCategoryTitles = fetchedCategories
        .filter((category) => category.name.en.toLowerCase().includes(searchString) || category.name.ar.toLowerCase().includes(searchString))
        .map((category) => category.id);

      let matchedCoursesByCategoryName = userProfile.cachedCourses
        .filter((course) => matchedCategoryTitles
          .some((category) => category === course.parent_category_id || category === course.subCategoryID));

      let ids = new Set(searchedCoursesByTitle.map(course => course.id));
      let mergedResults = [...searchedCoursesByTitle, ...matchedCoursesByCategoryName.filter(d => !ids.has(d.id))];

      let coursesWithRatings = mergedResults.filter((course) => course.rating >= 0);
      let haveRatingsForEveryCourse = coursesWithRatings.length === mergedResults.length;
      if (!haveRatingsForEveryCourse) {
        // console.log("there aren't ratings fro every course");
        getCourseDetails(mergedResults)
      }
      else if (haveRatingsForEveryCourse && mergedResults.length === 0) {
        // console.log("no results");
        setCourses([]);
        setCategoryList([]);
        setLoading(false);
      }
      else {
        // console.log("there are ratings for every course");
        let relevantCategories = getRelevantCategoriesAndSubCategories(mergedResults);

        let filteredLanguages = new Set(mergedResults.filter(course => course.custom_field_3 !== null).map(course => course.custom_field_3));
        // console.log(filteredLanguages);
        setAvailableLanguages({
          English: {
            checked: false,
            disabled: !filteredLanguages.has("English")
          },
          Arabic: {
            checked: false,
            disabled: !filteredLanguages.has("Arabic")
          }
        });
        setCategoryList(relevantCategories);
        setCourses(mergedResults);
        setLoading(false);
      }
      // let relevantCategories = getRelevantCategoriesAndSubCategories(mergedResults);

      // let finalizedResults = [];
      // for (let category in relevantCategories) {
      //   let parentID = relevantCategories[category].id;
      //   let categoryCourses = relevantCategories[category].courses;
      //   let hasRatings = categoryCourses.some((course) => course.rating >= 0);
      //   if (!hasRatings) {
      //    getCategoryDetails(parentID, categoryCourses);
      //     // console.log("after request", categoryCourses);
      //     // finalizedResults.push(...coursesWithRatings);
      //   }
      //   else {
      //     setCourses(prev => [...prev, categoryCourses]);
      //   }
      // }



      // console.log("relevantCategories ", relevantCategories)
      // console.log("mergedResults", mergedResults);
      // setCourses(mergedResults);
      // setLoading(false);
      // console.log(matchedCategoryTitles);
    }
  }, [userProfile.cachedCourses.length, loading, courses])

  // setCourses(searchedCourses);

  // console.log(searchedCourses);

  return (
    <div className="search-page">
      <PageHeader lang={lang} title={title} />
      {!loading ? (
        courses.length === 0 ? (
          <div className="no-results">
            <div className="no-results-text">
              We couldn't find any results for "{query.get("q")}"
            </div>
            <div>
              <HelpIcon style={{ fontSize: '200px', color: "black" }} />
            </div>
          </div>
        )
          :
          (
            <div className="search-container">

              <div className={`search-container-header ${lang === "ar" ? "rtl" : ""}`}>{lang === "ar" ? <bdi>{courses.length} نتائج لـلبحث عن "{query.get("q")}"</bdi> : `${courses.length} results for "${query.get("q")}"`}</div>
              <div className={`search-sort-dropdown ${lang === "ar" ? "rtl" : ""}`}>

                <Button onClick={() => setShowFilter(true)} className="filter-sidebar-drawer-button" icon={<TuneIcon />} color={"orange-transparent"}>{labels.filterButton[lang]}</Button>


                <SortDropdown lang={lang} courses={courses} coursesToSort={coursesToSort} setCoursesHandler={setCoursesHandler} />
              </div>
              <div className={`search-content ${lang === "ar" ? "rtl" : ""}`}>

                <div className="filter-sidebar-container">
                  <div className="filter-sidebar">
                    <div className="tree-filter-sidebar-header">{labels.categories[lang]}</div>
                    <CheckboxTree lang={lang}
                      categoryList={categoryList}
                      courses={courses}
                      setCoursesHandler={setCoursesHandler}
                      courseSortHandler={courseSortHandler}
                      location={props.location.search}
                      availableLanguages={availableLanguages}
                    />
                    {
                      //   <div className="filter-sidebar-header">
                      //     Course languages
                      //  </div>
                      //   <SidebarLanguageCheckboxes lang={lang} courses={courses} setCoursesHandler={setCoursesHandler} />

                    }
                  </div>
                </div>
                <Drawer
                  anchor={lang === "ar" ? "left" : "right"}
                  open={showFilter}
                  variant="persistent"
                  classes={drawerClasses}
                  style={{ zIndex: 2001 }}

                >
                  <div className={`filter-drawer ${lang === "ar" ? "rtl" : ""}`}>
                    <div className="filter-drawer-header">
                      <div className="filter-drawer-close-button" onClick={() => setShowFilter(false)}>
                        <CloseIcon />
                      </div>
                      <div className="filter-drawer-title">
                        {labels.filterButton[lang]}
                      </div>
                    </div>
                    <div className="filter-sidebar" >
                      <div className="tree-filter-sidebar-header">{labels.categories[lang]}</div>
                      <CheckboxTree lang={lang}
                        categoryList={categoryList}
                        courses={courses}
                        setCoursesHandler={setCoursesHandler}
                        courseSortHandler={courseSortHandler}
                        location={props.location.search}
                        availableLanguages={availableLanguages}

                      />
                      {
                        //   <div className="filter-sidebar-header">
                        //     Course languages
                        //  </div>
                        //   <SidebarLanguageCheckboxes lang={lang} courses={courses} setCoursesHandler={setCoursesHandler} />

                      }
                    </div>
                  </div>
                </Drawer>
                <div className="filtered-results">
                  <CourseList lang={lang} courses={courses} />
                </div>
              </div>
            </div>
          )
      ) :
        (
          <div className="courses-loading-spinner"><LoadingSpinner /></div>
        )
      }
    </div>)
}

export default Search;