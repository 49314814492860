import React, { useState } from "react";


import "./LanguageSwitchMobile.css";
import { useSelector, useDispatch } from "react-redux";
import { selectUserProfile, toggleLanguage } from "../../reducers/userProfileSlice";

const LanguageSwitchMobile = () => {
  // change back to this later
  const userProfile = useSelector(selectUserProfile);

  const lang = userProfile.language;
  // const [checked, setChecked] = useState(userProfile.language === "ar" ? true : false);
  // const [checked, setChecked] = useState(true);
  const dispatch = useDispatch();
  const handleChange = () => {
    // setChecked((prev) => !prev);
    dispatch(toggleLanguage({ lang }));
  }
  return (
    <div className="language-switch-mobile" onClick={handleChange}>
    {lang === "ar" ? "EN" : "AR"}
    </div>
  )
}

export default LanguageSwitchMobile;