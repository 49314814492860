import React, { useState, useEffect } from "react";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import OrangeCheckbox from "./OrangeCheckbox";
import RTL from "../RTL/RTL";
// OrangeCheckbox
const SubcategoryCheckboxGroup = (props) => {
  const [state, setState] = useState(props.subcategoryState);
  let lang = props.lang;
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    // if(event.target.checked){
    props.setSubCategoryFilter({ [event.target.name]: event.target.checked });
    // }
  };
  const useStyles = makeStyles({
    root: {
      backgroundColor: "black",
      color: "white",
      marginRight: "5%"
    }
  });
  let labels = {
    sectionHeader: {
      en: "Subcategories",
      ar: 'الفئات الفرعية'
    }
  }
  useEffect(() => {
    // console.log("Checkbox mounted");
    setState(props.subcategoryState);
  }, [])
  // const OrangeCheckbox = withStyles({
  //   root: {
  //     color: "rgb(255, 75, 18)",
  //     '&$checked': {
  //       color: "rgb(255, 75, 18)",
  //     },
  //   },
  //   checked: {},
  // })((props) => <Checkbox color="default" {...props} />);
  const theme = createMuiTheme({
    direction: "rtl"
  });
  const classes = useStyles();

  const SubCategoryCheckboxesLTR = (
    <FormGroup classes={classes}>
    <div className="filter-section-header">{labels.sectionHeader[lang]}</div>
    {props.subcategories.map((subcategory) => (
      <FormControlLabel
        key={subcategory.id}
        control={<OrangeCheckbox checked={state[subcategory.id]} onChange={handleChange} name={subcategory.id} />}
        label={subcategory.name[lang]}
      />
    ))}
  </FormGroup>
  );

  const SubCategoryCheckboxesRTL = (
    <RTL>
    <ThemeProvider theme={theme}>
      <span dir="rtl">
        <FormGroup classes={classes}>
          <div className="filter-section-header">{labels.sectionHeader[lang]}</div>
          {props.subcategories.map((subcategory) => (
            <FormControlLabel
              key={subcategory.id}
              control={<OrangeCheckbox checked={state[subcategory.id]} onChange={handleChange} name={subcategory.id} />}
              label={subcategory.name[lang]}
            />
          ))}
        </FormGroup>
      </span>
    </ThemeProvider>
  </RTL>
  )
  return (lang === "ar" ? SubCategoryCheckboxesRTL: SubCategoryCheckboxesLTR);
}

export default SubcategoryCheckboxGroup;