import React, { useState, useEffect } from "react";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import OrangeCheckbox from "../shared/components/UIElements/PageHeader/OrangeCheckbox";
import RTL from "../shared/components/UIElements/RTL/RTL"
const SidebarLanguageCheckboxes = (props) => {
  const [state, setState] = useState(props.languageState);
  // console.log(props.languageState);
  let lang = props.lang;
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: { ...state[event.target.name], checked: event.target.checked} });
    // if(event.target.checked){
    // props.setLanguageFilter({ [event.target.name]: event.target.checked });
    props.setLanguageFilter([event.target.name], { ...state[event.target.name], checked: event.target.checked})

    // [event.target.name]: { ...state[event.target.name], checked: event.target.checked}
    // }

  };

  let labels = {
    englishFilter: {
      en: "English",
      ar: "الإنجليزية"
    },
    arabicFilter: {
      en: "Arabic",
      ar: "عربى"
    },
    sectionHeader: {
      en: "Languages",
      ar: "لغات"
    }
  };

  // useEffect(() => {
  //   // console.log("Checkbox mounted");
  //   setState(props.languageState);
  // }, [])

  const useStyles = makeStyles({
    root: {
      color: "white",
    },
  });

  const classes = useStyles();

  const arabicStyles = makeStyles({
    root: {
      color: "white",
    }
  });

  const arabicClasses = arabicStyles();
  const theme = createMuiTheme({
    direction: "rtl"
  });

  const labelStyles = makeStyles({
    disabled: {
      color: "grey"
    },
    label: {
      '&$disabled': {
        color: "grey"
      }
    }
  })
  const labelClasses = labelStyles();

  const LanguageCheckboxesLTR = (
    <FormGroup classes={classes}>

      <FormControlLabel
        classes={labelClasses}
        control={<OrangeCheckbox checked={state.English.checked} onChange={handleChange} disabled={state.English.disabled} name={"English"} />}
        label={labels.englishFilter[lang]}
      />
      <FormControlLabel
        classes={labelClasses}
        control={<OrangeCheckbox checked={state.Arabic.checked} onChange={handleChange} disabled={state.Arabic.disabled} name={"Arabic"} />}
        label={labels.arabicFilter[lang]}
      />
    </FormGroup>
  );

  const LanguageCheckboxesRTL = (
    <RTL>
      <ThemeProvider theme={theme}>
        <span dir="rtl">
          <FormGroup classes={arabicClasses}>
            <FormControlLabel
              classes={labelClasses}
              control={<OrangeCheckbox checked={state.English.checked} disabled={state.English.disabled} onChange={handleChange} name={"English"} />}
              label={labels.englishFilter[lang]}
            />
            <FormControlLabel
              classes={labelClasses}
              control={<OrangeCheckbox checked={state.Arabic.checked} disabled={state.Arabic.disabled} onChange={handleChange} name={"Arabic"} />}
              label={labels.arabicFilter[lang]}
            />
          </FormGroup>
        </span>
      </ThemeProvider>
    </RTL>
  )

  return (
    lang === "ar" ? LanguageCheckboxesRTL : LanguageCheckboxesLTR
  )
}

export default SidebarLanguageCheckboxes;