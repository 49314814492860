import React, { useState, useEffect } from "react";
// import "./CategorySelector.css";
// import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import CardSlider from "../CardSlider/CardSlider";
import { useSelector, useDispatch } from "react-redux";
import { selectUserProfile, toggleLanguage, setCachedCourses, updateCachedCourses, updateCategoryCourses } from "../../../reducers/userProfileSlice";
import axios from "axios";
// import courseGrabber from "../../../helpers/courseGrabber";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import categoryRatingGrabber from "../../../helpers/categoryRatingGrabber";
import LoadingSlider from "../CardSlider/LoadingSlider";
const CategorySelector = (props) => {
  let lang = props.lang;
  const userProfile = useSelector(selectUserProfile);
  const dispatch = useDispatch();

  let labels = [
    {
      en: "BUSINESS",
      ar: "إدارة الاعمال",
      id: "18"
    },
    {
      en: "PERSONAL DEVELOPMENT & LIFE STYLE",
      ar: "تطوير الذات و مهارات الحياة",
      id: "28"
    }
  ];
  const [tabIndex, setTabIndex] = useState(0);


  // useEffect(() => {

  // }, [tabIndex, userProfile.categories])

  const [courses, setCourses] = useState({
    "0": null,
    "1": null,
  });


  // useEffect(() => {
  //   if (userProfile.categories.length > 0) {
  //     for (let label in labels) {
  //       let filteredCourses = userProfile.categories.filter(category => category.id === labels[label].id);
  //       let filteredCoursesWithRatings = filteredCourses[0].courses.filter((course) => course.rating);
  //       setCourses((prev) => { return { ...prev, [label]: filteredCoursesWithRatings } })
  //     }
  //   }
  // }, [userProfile.categories, courses])
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   let mounted = true;
  //   const generateCourseSlider = async () => {
  //     if (userProfile.cachedCourses.length > 0) {
  //       if (mounted) {
  //         setCourses(userProfile.cachedCourses);
  //       }
  //     }
  //     else {
  //       let courseList = await courseGrabber();
  //       if (mounted) {
  //         setCourses(courseList);
  //         dispatch(setCachedCourses({ courses: courseList }))
  //       }
  //     }
  //   }

  //   generateCourseSlider();

  //   return () => {
  //     mounted = false;
  //   }
  // }, [])

  // useEffect(() => {
  //   const grabCategoryTab = async () => {
  //     setLoading(true);
  //     try {
  //       let options = {
  //         method: "post",
  //         url: `/api/v1/categories/id:${labels[tabIndex].id}`,
  //         data: {
  //           courses: courses[tabIndex].courses[0]
  //         }
  //       }
  //       // let response = await axios(options);
  //       // let courses = await categoryRatingGrabber(response.data)
  //       // move this to the back-end later

  //       // setCourses((prev) => { return { ...prev, [tabIndex]: [response.data] } })
  //       // setCourses(prev => ({ ...prev, [tabIndex]: courses }));
  //       // setLoading(false);
  //     }
  //     catch (error) {
  //       console.log(error);
  //     }

  //   }
  //   if (userProfile.categories.length > 0) {
  //     console.log("it ran");
  //     grabCategoryTab();
  //     console.log(courses);
  //   }

  //   console.log(courses);


  // }, [tabIndex, userProfile.categories.length]);


  // useEffect(() => {

  // }, )



  useEffect(() => {
    const getCategoryDetails = async (categoryCourses) => {
      setLoading(true);
      // console.log(tabIndex);
      // console.log("request fired");
      let categoryDetails = await categoryRatingGrabber(categoryCourses);
      dispatch(updateCategoryCourses({ parentID: labels[tabIndex].id, courses: categoryDetails }));
      dispatch(updateCachedCourses({ courses: categoryDetails }))
      setCourses((prev) => { return { ...prev, [tabIndex]: categoryDetails } })
      setLoading(false);
      // return categoryDetails;
    }

    if (userProfile.categories.length > 0 && !courses[tabIndex]) {
      let categoryIndex = userProfile.categories.findIndex((category) => category.id === labels[tabIndex].id)

      let categoryCourses = userProfile.categories[categoryIndex].courses;
      let categoryCoursesWithRatings = userProfile.categories[categoryIndex].courses.filter((course) => course.rating >= 0);
      // let hasRatings = categoryCourses.some((course) => course.rating >= 0);
      let hasRatings = categoryCoursesWithRatings.length === categoryCourses.length;
      // let category = userProfile.categories.filter((category) => category.id === labels[tabIndex].id);
      // let hasRatings = category[0].courses.some((course) => course.rating >= 0);
      if (!hasRatings) {
        getCategoryDetails(categoryCourses);
      }
      else {
        setCourses((prev) => { return { ...prev, [tabIndex]: categoryCourses } });
        setLoading(false);
      }
    }
    // if(!courses[tabIndex]){
    //   if(userProfile.categories.length > 0){
    //     let category = userProfile.categories.filter((category) => category.id === labels[tabIndex].id);
    //     let hasRatings = category[0].courses.some((course) => course.rating);
    //     if(!hasRatings){
    //       getCategoryDetails();
    //     }
    //     else{
    //       setCourses((prev) => { return { ...prev, [tabIndex]: category[0].courses } });
    //     }
    //   }
    // }

    // if(userProfile.categories.length > 0 && courses[tabIndex] && courses[tabIndex].length > 0){
    //   let localHasRatings = courses[tabIndex].some((course) => course.rating);
    //   let category = userProfile.categories.filter((category) => category.id === labels[tabIndex].id);
    //   let globalHasRatings = category[0].courses.some((course) => course.rating);
    //   if(globalHasRatings && localHasRatings){
    //     setCourses((prev) => { return { ...prev, [tabIndex]: category[0].courses } });
    //   }
    // }
    // else if (userProfile.categories.length > 0 && !courses[tabIndex]){
    //   let category = userProfile.categories.filter((category) => category.id === labels[tabIndex].id);
    //   let globalHasRatings = category[0].courses.some((course) => course.rating);
    //   if(globalHasRatings){
    //     setCourses((prev) => { return { ...prev, [tabIndex]: category[0].courses } });
    //   }
    //   else{
    //     getCategoryDetails();
    //   }
    // }
    // if (userProfile.categories.length > 0 && !courses[tabIndex]) {
    //   let category = userProfile.categories.filter((category) => category.id === labels[tabIndex].id);
    //   // let hasRatings = category[0].courses.some((course) => course.rating);

    //   if (!hasRatings) {
    //     getCategoryDetails();
    //   }
    //   else {
    //     setCourses((prev) => { return { ...prev, [tabIndex]: category[0].courses } });
    //   }
    // }
  }, [userProfile.categories, tabIndex])


  const tabsStyles = makeStyles({
    root: {
      width: '100%',
      boxShadow: 'inset 0 -1px 0 0 #E6ECF0',
      color: "#ff4b12",
    },
    indicator: {
      backgroundColor: '#ff4b12',
    },
  });

  const tabItemStyles = makeStyles({
    root: {
      minHeight: 53,
      minWidth: 80,
      '&:hover': {
        backgroundColor: 'rgba(255, 52, 0, 0.1)',
        '& $wrapper': {
          color: '#ff4b12',
        },
      },
      '&$selected': {
        '& *': {
          color: '#ff4b12',
        },
      },
    },
    selected: {},
    textColorInherit: {
      opacity: 1,
    },
    wrapper: {
      textTransform: 'uppercase',
      fontSize: 15,
      fontWeight: 700,
      color: 'white',
    },
  });
  useEffect(() => {
    if (lang === "ar") {
      setTabIndex(labels.length - 1)
    }
    else {
      setTabIndex(0);
    }
  },
    [lang, labels.length])
  // let useStyles = makeStyles(isEnglish ? defaultStyle : arabicStyle);

  // const classes = useStyles();

  const tabClasses = tabsStyles();
  const tabItemClasses = tabItemStyles();
  return (
    <div>

      <Tabs
        variant={'fullWidth'}
        classes={tabClasses}
        value={tabIndex}
        onChange={(e, index) => setTabIndex(index)}
      >
        {
          labels.map((label, index) => <Tab key={label[lang] + index} classes={tabItemClasses} label={label[lang]} />)
        }
      </Tabs>
      {!loading ? (
        <CardSlider lang={lang} courses={courses[tabIndex]} loading={loading} />

      )
        :
        (
          <LoadingSlider />
        )
      }
    </div>
  )
}

export default CategorySelector;