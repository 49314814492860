import React from 'react';

import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import "./ErrorModal.css";
const ErrorModal = props => {
  return (
    <Modal
      onCancel={props.onClear}
      className="error-modal"
      headerClass="error-modal__header"
      show={!!props.error}
      footer={<Button color={"orange"} onClick={props.onClear}>Okay</Button>}
    >
      <p>{props.error}</p>
    </Modal>
  );
};

export default ErrorModal;
