import React, { useState, useEffect } from "react";
import "./PageHeader.css";
import TuneIcon from '@material-ui/icons/Tune';
import Button from "../Button/Button";
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import FilterPopper from "./FilterPopper";
import SortPopper from "./SortPopper";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const PageHeader = (props) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [openRating, setOpenRating] = useState(false);
  const [openPrice, setOpenPrice] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  // const [sortSettings, setSortSettings] = useState({
  //   rating: "descending"
  // })
  const [filterCalled, setFilterCalled] = useState(0);
  let labels = {
    filterButton: {
      en: "Filter",
      ar: "فلتر"
    },
    priceButton: {
      en: "Price",
      ar: "رسوم"
    },
    ratingButton:{
      en: "Rating",
      ar: "التقيم"
    }
  }

  const handleClick = (event, type) => {
    switch (type) {
      case "rating":
        setOpenPrice(false);
        setOpenFilter(false);
        setAnchorEl(event.currentTarget);
        setOpenRating((prev) => !prev);
        break;
      case "price":
        setOpenRating(false);
        setOpenFilter(false)
        setAnchorEl(event.currentTarget);
        setOpenPrice((prev) => !prev);
        break;
      case "filter":
        setOpenRating(false);
        setOpenPrice(false);
        setAnchorEl(event.currentTarget);
        setOpenFilter((prev) => !prev);
        break;
    }
  }



  // const updateSortSettings = (setting) => {

  //   setSortSettings((prev) => ({...prev, ...setting}));
  // }

  const updateFilterCalled = () => {
    setFilterCalled((prev) => prev + 1);
  }

  let lang = props.lang;
  return (
    <div className={`page-header ${lang === "ar" ? "rtl" : ""} ${props.sticky ? "with-sticky" : ""} `}>
      <div className={`page-header-buttons ${lang === "ar" ? "rtl" : ""}`}>
        {props.withFilter && (
          <div className={`page-header-filter`}>
            <div className="filter-button">
              <Button onClick={(event) => handleClick(event, "filter")} icon={<TuneIcon />} color={"orange-transparent"}>{labels.filterButton[lang]}</Button>
              {

                <FilterPopper
                  id={"subcategory-filter-popper"}
                  open={openFilter}
                  anchorEl={anchorEl}
                  lang={lang}
                  subcategories={props.subcategories}
                  courses={props.courses}
                  setCoursesHandler={props.setCoursesHandler}
                  parentCategoryID={props.parentCategoryID}
                  updateFilterCalled={updateFilterCalled}
                />
              }
            </div>
          </div>
        )}
        {props.withSort && (
          <React.Fragment>
            <div className="page-header-sort">
              <div className="sort-ratings-button">
                <Button color={"orange-transparent"} onClick={(event) => handleClick(event, "rating")}>{labels.ratingButton[lang]}<span><ExpandMoreIcon /></span></Button>
                <SortPopper
                  id={"rating-sort-popper"}
                  open={openRating}
                  anchorEl={anchorEl}
                  lang={lang}
                  subcategories={props.subcategories}
                  default={"descending"}
                  sortBy={"rating"}
                  courses={props.courses}
                  setCoursesHandler={props.setCoursesHandler}
                  filterCalled={filterCalled}
                />
              </div>

            </div>
            <div className="page-header-sort">
              <div className="sort-ratings-button">
                <Button color={"orange-transparent"} onClick={(event) => handleClick(event, "price")} >{labels.priceButton[lang]}<span><ExpandMoreIcon /></span></Button>
                <SortPopper
                  id={"price-sort-popper"}
                  open={openPrice}
                  anchorEl={anchorEl}
                  lang={lang}
                  subcategories={props.subcategories}
                  sortBy={"price"}
                  courses={props.courses}
                  setCoursesHandler={props.setCoursesHandler}
                  filterCalled={filterCalled}
                />
              </div>
            </div>
          </React.Fragment>
        )}

      </div>
      <div className={`page-header-title-container ${lang === "ar" ? "rtl" : ""}`}>
        <div className={`page-header-title ${lang === "ar" ? "rtl" : ""}`}>
          {props.title[lang]}
        </div>
      </div>
    </div>
  )

}

export default PageHeader;