import React from "react";
import "./Footer.css";
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import logo from "../../../images/logo.jpg";
import Button from "@material-ui/core/Button";
import { useLocation } from "react-router-dom"
import { useHistory } from "react-router-dom";
const Footer = (props) => {
  let lang = props.lang;
  let history = useHistory();
  let labels = {
    copyright: {
      en: "Copyright © 2020 DARIBNY.",
      ar: "Copyright © 2020 DARIBNY حقوق النشر والتوزيع محفوظة"
    },
    privacyAndCookies: {
      en: "Privacy Policy and Cookie Policy",
      ar: "سياسة الخصوصية وسياسة ملفات تعريف الارتباط"
    },
    terms: {
      en: "Terms and conditions policy",
      ar: "سياسة الشروط والأحكام"
    }
  }

  const footerTermsHandler = () => {
    history.push("/terms");
  }
  return (
    <div className="footer">
      <div className={`footer-content ${lang === "ar" ? "rtl" : ""}`} >
        <div className={`footer-branding-info ${lang === "ar" ? "rtl" : ""}`}>
          <div className={`footer-logo-container `}>
            <img className="footer-logo" src={logo} />
          </div>
          <div className="footer-legal-text">
            {labels.copyright[lang]}
          </div>
        </div>
        <div className={`social-media-links ${lang === "ar" ? "rtl" : ""}`}>
          <a href="https://www.facebook.com/thecoachingtalks/">
            <FacebookIcon className="circle-icon" />
          </a>
          <a href="https://www.instagram.com/the_coaching_talks">
            <InstagramIcon className="circle-icon" />
          </a>
          <a href="https://www.youtube.com/channel/UCJY_k5OsclLKkUIE_FYBtDA">
            <YouTubeIcon className="circle-icon" />
          </a>
          <a href="https://twitter.com/thecoachingtalk">
            <TwitterIcon className="circle-icon" />
          </a>
          <a href="https://www.linkedin.com/in/hassan-al-hasani-acc-cma-187a8131/">
            <LinkedInIcon className="circle-icon" />
          </a>
        </div>
        <div className={`footer-legal-info ${lang === "ar" ? "rtl" : ""}`}>
          <div className="footer-legal-text" onClick={footerTermsHandler}>
            {labels.terms[lang]}
          </div>
        </div>
      </div>


      <div className={`footer-content-mobile ${lang === "ar" ? "rtl" : ""}`} >





        <div className={`footer-branding-info ${lang === "ar" ? "rtl" : ""}`}>
          <div className={`footer-logo-container `}>
            <img className="footer-logo" src={logo} />
          </div>
          <div className={`social-media-links ${lang === "ar" ? "rtl" : ""}`}>
            <a href="https://www.facebook.com/DARIBNY/?modal=admin_todo_tour">
              <FacebookIcon className="circle-icon" />
            </a>
            <a href="https://www.instagram.com/the_coaching_talks">
              <InstagramIcon className="circle-icon" />
            </a>
            <a href="https://www.youtube.com/channel/UCJY_k5OsclLKkUIE_FYBtDA">
              <YouTubeIcon className="circle-icon" />
            </a>
            <a href="https://twitter.com/thecoachingtalk">
              <TwitterIcon className="circle-icon" />
            </a>
            <a href="https://www.linkedin.com/in/hassan-al-hasani-acc-cma-187a8131/">
              <LinkedInIcon className="circle-icon" />
            </a>
          </div>

        </div>

        <div className={`footer-legal-info ${lang === "ar" ? "rtl" : ""}`}>
          <div className="footer-legal-text">
            {labels.copyright[lang]}
          </div>
          <div className="footer-legal-text" onClick={footerTermsHandler}>
            {labels.terms[lang]}
          </div>

        </div>





      </div>

    </div>
  )
}

export default Footer;