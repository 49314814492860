
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Rating from '@material-ui/lab/Rating';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useHistory } from "react-router-dom";
const CourseCard = (props) => {
  let isEnglish = props.lang === "en";
  let history = useHistory();
  // Move this to the back-end later
  let hasLangOptions = typeof props.title === 'object' && props.title !== null;
  let defaultStyle = {
    card: {
      maxWidth: 250,
      backgroundColor: "black",
      textOverflow: "ellipsis",
      color: "white",
      maxHeight: 300,
      textAlign: "left",
    },
    media: {
      height: 122,
    },
    typography: {
      color: "white"
    },
    rating: {
      width: 100,
      display: "flex",
      alignItems: "center",
      marginBottom: "10%"
    },
    ratingNumber: {
      paddingTop: "5%",
      fontSize: "12px",
      marginLeft: 8,
      fontWeight: 700,
      color: "white"
    },
    userCount: {
      paddingTop: "4%",
      fontSize: "13px",
      marginLeft: 6,

    },
    price: {
      textAlign: "right",
    }
  };

  let arabicStyle = {
    ...defaultStyle,
    card: {
      ...defaultStyle.card,
      direction: "rtl",
      textAlign: "right",

    },
    rating: {
      ...defaultStyle.rating,
      direction: "ltr",
      flexDirection: "row-reverse",
      alignItems: "right",
      justifyContent: "flex-start",
    },
    ratingNumber: {
      ...defaultStyle.ratingNumber,
      marginRight: "10%",
      paddingTop: "4%"
    },
    userCount: {
      ...defaultStyle.userCount,
      paddingTop: "3%"
    },
    price: { textAlign: "left" }
  };
  let useStyles = makeStyles(isEnglish ? defaultStyle : arabicStyle);

  const classes = useStyles();
  const colortheme = createMuiTheme({
    palette: {
      primary: { main: "#000", contrastText: "#000" },
    }
  });

  const mediaHandler = () => {
    history.push(`/course/${props.id}`);
  }

  return (
    <ThemeProvider theme={colortheme}>
      <Card className={classes.card}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={props.image}
            title={hasLangOptions ? props.title[props.lang] : props.title}
            onClick={mediaHandler}
          />
        </CardActionArea>
        <CardContent>
          <Typography variant="h6" component="h3" noWrap={true}>
            {hasLangOptions ? props.title[props.lang] : props.title}
          </Typography>
          <Typography variant="body2" gutterBottom >
            {props.instructor}
          </Typography>
          <div className={classes.rating}>
            <Rating
              size="small"
              value={props.rating}
              precision={0.5}
              readOnly
              emptyIcon={<StarBorderIcon style={{ color: "white" }} fontSize="inherit" />}
            />
            <Box className={classes.ratingNumber} ml={2}>{props.rating}</Box>
            {props.userCount && (
              <Box className={classes.userCount} ml={2}>({props.userCount})</Box>
            )}
          </div>
          <Typography variant="h5" align="right" className={classes.price}>
            {props.price}
          </Typography>
        </CardContent>
      </Card>
    </ThemeProvider>
  )
}

export default CourseCard;