import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { AppBar, IconButton, Typography, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./Home/components/Home";
import Header from "./shared/components/Header/Header";
import Footer from "./shared/components/Footer/Footer";
import { useSelector, useDispatch } from "react-redux";
import { selectUserProfile, toggleLanguage, setLoggedIn } from "./shared/reducers/userProfileSlice";
import Courses from "./Courses/Courses";
import Course from "./Courses/Course";
import Cookie from "universal-cookie";
import Cart from "./Cart/Cart";
import Payment from "./Payment/Payment";
import Category from "./Courses/Category";
import Search from "./Search/Search";
import Terms from "./Terms/Terms";
function App() {

  const userProfile = useSelector(selectUserProfile);
  let lang = userProfile.language;
  let routes;
  const cookies = new Cookie();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  routes = (
    <React.Fragment>
      <Header lang={lang} />
      <Switch>
        <Route path="/course/:id" render={(props) => <Course {...props} />} />
        <Route path="/courses" render={(props) => (
          <React.Fragment>
            <Route path={`${props.match.url}/`} exact>
              <Courses lang={lang} />
            </Route>
            <Route path={`${props.match.url}/:categoryid`} exact render={(props) => (
              <Category {...props} />
            )} />
            <Route path={`${props.match.url}/:categoryid/:subcategoryid`} exact render={(props) => (
              <Category {...props} />
            )} />
          </React.Fragment>
        )}>

        </Route>
        <Route path="/cart">
          <Cart lang={lang} />
        </Route>
        <Route path="/terms">
          <Terms lang={lang} />
        </Route>
        <Route path="/search" render={(props) => <Search {...props} />} />
        <Route path="/payment">
          <Payment lang={lang} />
        </Route>
        <Route path="/">
          <Home lang={lang} />
        </Route>
      </Switch>
      <Footer lang={lang} />
    </React.Fragment>
  )

  return (
    <Router>
      <div className="App">
        {routes}
      </div>
    </Router>
  );
}

export default App;
