
import axios from "axios";

const userCoursesGrabber = async () => {
  try {
    let options = {
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/usercourses`,
      withCredentials: true
    };

    let response = await axios(options);

    return response.data.courses;
  }
  catch (error) {
    throw new Error(error.response.data.error);
  }
}

export default userCoursesGrabber;