import React, { useState, useEffect } from "react";
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import RTL from "../shared/components/UIElements/RTL/RTL";
const SortDropdown = (props) => {
  let lang = props.lang;

  const [sort, setSort] = useState({
    sortBy: "default",
    order: "default"
  });

  let labels = {
    sortBy: {
      en: "Sort By",
      ar: "ترتيب حسب"
    },
    mostRelevant: {
      en: "Most Relevant",
      ar: "الأكثر صلة"
    },
    priceHighToLow: {
      en: "Price: High to Low",
      ar: "السعر: الاعلى الى الادنى"
    },
    priceLowToHigh: {
      en: "Price: Low to High",
      ar: "السعر: من الارخص للاعلى"
    },
    ratingHighToLow: {
      en: "Rating: High to Low",
      ar: "التصنيف: من الأعلى إلى الأقل"
    },
    ratingLowToHigh: {
      en: "Rating: Low to High",
      ar: "التصنيف: من الأقل إلى الأعلى"
    }
  };

  const options = [
    { sortBy: "default", order: "default" },
    { sortBy: "rating", order: "descending" },
    { sortBy: "rating", order: "ascending" },
    { sortBy: "price", order: "descending" },
    { sortBy: "price", order: "ascending" }
  ]
  // const [originalCourseList, setOriginalCourseList] = useState([...props.courses]);
  // MuiFormControl: {
  //   margin: theme.spacing(1),
  //   minWidth: 120,
  //   verticalAlign: "none",
  //   root: {

  //     verticalAlign: 'none', // Fix alignment issue on Safari.
  //   }
  // },
  const useStyles = makeStyles((theme) => ({
    MuiFormControl: {
      margin: theme.spacing(1),
      minWidth: 200,
      [theme.breakpoints.between('xs', 'sm')]: {
       minWidth: 150
      },
    },
    select: {
      root: {
        color: "white"
      },
      "& ul": {
        backgroundColor: "black",
      },
      "& .MuiListSubheader-root": {
        backgroundColor: "rgb(22, 22, 22)"
      },
      "& li": {
        fontSize: 16,
        color: "white",
        '&:hover, &:focus': {
          backgroundColor: "rgb(255, 75, 18)",
        }
      },
    },
    icon: {
      fill: "rgb(255, 75, 18)",
    },
  }));

  const arabicStyles = makeStyles((theme) => ({
    MuiFormControl: {
      margin: theme.spacing(1),
      minWidth: 200,
      [theme.breakpoints.between('xs', 'sm')]: {
        minWidth: 150,
        width: 150
       },
    },
    select: {
      root: {
        color: "white"
      },
      "& ul": {
        backgroundColor: "black",
        direction:"rtl"
      },
      "& .MuiListSubheader-root": {
        backgroundColor: "rgb(22, 22, 22)"
      },
      "& li": {
        fontSize: 16,
        color: "white",
        '&:hover, &:focus': {
          backgroundColor: "rgb(255, 75, 18)",
        }
      },
    },
    icon: {
      fill: "rgb(255, 75, 18)",
    },
  }));

  const baseTheme = {
    overrides: {
      MuiFormControl: {
        root: {
          verticalAlign: "none",
          borderColor: "orange"
        },


      },
      MuiInputLabel: {
        outlined: {
          backgroundColor: "rgb(22, 22, 0)",
          color: "white",
          paddingLeft: 2,
          paddingRight: 2
        },
        root: {
          color: "white",
          "&$focused": { // increase the specificity for the pseudo class
            color: "white"
          }
        }
      },
      MuiInputBase: {
        input: {
          color: "white",
        },
      }
    }
  }
  const themeLTR = createMuiTheme(baseTheme);
  const themeRTL = createMuiTheme({
    direction: "rtl",
    ...baseTheme
  });
  const classes = useStyles();
  const arabicClasses = arabicStyles();

  const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
      "& $notchedOutline": {
        borderColor: "#ff3400"
      },
      "&:hover $notchedOutline": {
        borderColor: "#ff3400"
      },
      "&$focused $notchedOutline": {
        borderColor: "#ff3400"
      }
    },
    focused: {},
    notchedOutline: {}
  }));

  const outlinedInputClasses = useOutlinedInputStyles();

  const handleChange = (event) => {
    // console.log(event.target.value);
    // console.log(Number.isInteger(event.target.value))
    if (Number.isInteger(event.target.value)) {
      setSort(options[event.target.value]);
    }
  }

  const sortByPrice = (courses, order) => {
    if (order === "ascending") {
      let courseList = [...courses];
      courseList.sort((a, b) => parseFloat(a[sort.sortBy].split("&#36;")[1].replace(/,/g, '')) - parseFloat(b[sort.sortBy].split("&#36;")[1].replace(/,/g, '')));

      // let courseList = courses.sort((a, b) => parseFloat(a[sort.sortBy].slice(5)) - parseFloat(b[sort.sortBy].slice(5)));
      // console.log(courseList[0][props.sortBy]);
      // console.log(courseList)
      props.setCoursesHandler([...courseList]);
    }
    else {
      let courseList = [...courses];
     courseList.sort((a, b) => parseFloat(b[sort.sortBy].split("&#36;")[1].replace(/,/g, '')) - parseFloat(a[sort.sortBy].split("&#36;")[1].replace(/,/g, '')));

      // let courseList = courses.sort((a, b) => parseFloat(b[sort.sortBy].slice(5)) - parseFloat(a[sort.sortBy].slice(5)));

      // console.log(courseList)
      props.setCoursesHandler([...courseList]);
    }
  }

  const sortByRating = (courses, order) => {
    if (order === "ascending") {
      let courseList = [...courses];
      courseList.sort((a, b) => a[sort.sortBy] - b[sort.sortBy]);
      // console.log(courseList[0][props.sortBy]);

      // let courseList = courses.sort((a, b) => a[sort.sortBy] - b[sort.sortBy]);
      // console.log(courseList);
      props.setCoursesHandler([...courseList]);
    }
    else {
      let courseList = [...courses];

      courseList.sort((a, b) => b[sort.sortBy] - a[sort.sortBy]);
      // let courseList = courses.sort((a, b) => b[sort.sortBy] - a[sort.sortBy]);
      // console.log(courseList);
      props.setCoursesHandler([...courseList]);
    }
  }

  const sortHandler = (courses, sort) => {
    if (sort.sortBy === "price") {
      sortByPrice(courses, sort.order)
    }
    else if (sort.sortBy === "rating") {
      sortByRating(courses, sort.order);
    }
    else {
      props.setCoursesHandler(courses);
    }
  }
  useEffect(() => {
    sortHandler(props.coursesToSort, sort);
  }, [sort, props.coursesToSort])


  let sortDropdownLTR = (
    <ThemeProvider theme={themeLTR} >
      <FormControl variant="outlined" classes={{ root: classes.MuiFormControl }}>
        {
          <InputLabel htmlFor="outlined-sort-simple">{labels.sortBy[lang]}</InputLabel>

        }
        <Select
          defaultValue={0}
          onChange={handleChange}
          MenuProps={{ classes: { paper: classes.select } }}
          inputProps={{
            classes: {
              icon: classes.icon,
            },
          }}
          input={
            <OutlinedInput
              name="sort-by"
              id="outlined-sort-simple"
              classes={outlinedInputClasses}
            />
          }
        >
          <MenuItem value={0}>
            {labels.mostRelevant[lang]}
          </MenuItem>

          <MenuItem value={1}>{labels.ratingHighToLow[lang]}</MenuItem>
          <MenuItem value={2}>{labels.ratingLowToHigh[lang]}</MenuItem>

          <MenuItem value={3}>{labels.priceHighToLow[lang]}</MenuItem>
          <MenuItem value={4}>{labels.priceLowToHigh[lang]}</MenuItem>
        </Select>
      </FormControl>
    </ThemeProvider>
  );

  let sortDropdownRTL = (
    <RTL>
      <ThemeProvider theme={themeRTL} >
        <span dir="rtl">
          <FormControl variant="outlined" classes={{ root: arabicClasses.MuiFormControl }}>
            {
              <InputLabel htmlFor="outlined-sort-simple">{labels.sortBy[lang]}</InputLabel>

            }
            <Select
              defaultValue={0}
              onChange={handleChange}
              MenuProps={{ classes: { paper: arabicClasses.select } }}
              inputProps={{
                classes: {
                  icon: arabicClasses.icon,
                },
              }}
              input={
                <OutlinedInput
                  name="sort-by"
                  id="outlined-sort-simple"
                  classes={outlinedInputClasses}
                />
              }
            >
              <MenuItem value={0}>
                {labels.mostRelevant[lang]}
              </MenuItem>

              <MenuItem value={1}>{labels.ratingHighToLow[lang]}</MenuItem>
              <MenuItem value={2}>{labels.ratingLowToHigh[lang]}</MenuItem>

              <MenuItem value={3}>{labels.priceHighToLow[lang]}</MenuItem>
              <MenuItem value={4}>{labels.priceLowToHigh[lang]}</MenuItem>
            </Select>
          </FormControl>
        </span>
      </ThemeProvider>
    </RTL>
  )

  return lang === "ar" ? sortDropdownRTL : sortDropdownLTR
}

export default SortDropdown;